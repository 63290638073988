import React from "react";
import { Link } from "react-router-dom";

export default class PS extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/productservice.avif)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "250px",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
    };
    return (
      <>
        {/* Bắt đầu Slogan Một */}
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="text-white">Sản Phẩm và Dịch Vụ Thuê Xe</h1>
                <p className="text-white">
                  Tìm hiểu thêm về các sản phẩm Avis giúp trải nghiệm thuê xe
                  của bạn dễ dàng và thú vị hơn.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-3">
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/productservice/usa`}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">
                      Sản phẩm và dịch vụ cho thuê xe tại Mỹ và Canada
                    </h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      Khám phá các sản phẩm và dịch vụ cụ thể sẽ nâng cao trải
                      nghiệm thuê xe của bạn tại Mỹ và Canada.
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">Tìm Hiểu Thêm</label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/productservice/additional-driver`}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">Tài xế bổ sung</h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      Chia sẻ việc lái xe với người khác để giảm bớt những giờ
                      dài trên đường. Thêm nhiều tài xế có nghĩa là họ được bảo
                      hiểm để cùng chia sẻ việc lái xe theo thỏa thuận của bạn.
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">Tìm Hiểu Thêm</label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/productservice/child-seats`}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">Ghế trẻ em</h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      Chúng tôi cung cấp các ghế an toàn cho trẻ sơ sinh, trẻ em
                      và trẻ nhỏ. Chúng có sẵn tại quầy tùy thuộc vào tình trạng
                      có sẵn.
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">Tìm Hiểu Thêm</label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/productservice/fuel-option`}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">Tùy chọn nhiên liệu</h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      Hết nhiên liệu hoặc thiếu thời gian? Avis sẽ lo tất cả nhu
                      cầu đổ nhiên liệu của bạn... nếu bạn muốn chúng tôi làm
                      điều đó.
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">Tìm Hiểu Thêm</label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/productservice/onewaycarrental`}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">Thuê xe một chiều</h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      Đi từ điểm A đến điểm B mà không phải quay lại điểm bắt
                      đầu chỉ để trả xe thuê.
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">Tìm Hiểu Thêm</label>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
