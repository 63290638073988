import React from "react";

export default class ContentThree extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myBorder = {
      borderBottom: "5px solid rgb(0, 40, 95)",
      width: "40%",
    };
    const bottomRight = {
      position: "absolute",
      bottom: "0%",
      right: "0%",
      maxWidth: "50%",
      backgroundColor: "#fff",
    };
    return (
      <>
        {/* Bắt đầu Slogan One */}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-sm-12">
                <h3 className="text-black">
                  LỢI ÍCH TỪ ĐỐI TÁC DU LỊCH CỦA CHÚNG TÔI
                </h3>
              </div>
            </div>
            {/* <div className='row mt-2'>
                            <div className='col-sm-4 mt-3'>
                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <img className='img-fluid w-100' src={publicUrl+"assets/images/resources/img-7.webp"} />
                                    </div>
                                    <div className='col-sm-7'>
                                        <p style={{height:"100px",lineHeight:"1.3"}}>Giảm giá đến 30% với thanh toán trước cùng 10% hoàn lại dưới dạng thẻ quà tặng Amazon*</p>
                                        <a href='#' className='link-avis'><b>TÌM HIỂU THÊM</b></a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4 mt-3'>
                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <img className='img-fluid w-100' src={publicUrl+"assets/images/resources/img-8.webp"} />
                                    </div>
                                    <div className='col-sm-7'>
                                        <p style={{height:"100px",lineHeight:"1.3"}}>Tìm hiểu thêm về các ưu đãi độc quyền và tiết kiệm cho thành viên AARP</p>
                                        <a href='#' className='link-avis'><b>TÌM HIỂU THÊM</b></a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4 mt-3'>
                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <img className='img-fluid w-100' src={publicUrl+"assets/images/resources/img-9.webp"} />
                                    </div>
                                    <div className='col-sm-7'>
                                        <p style={{height:"100px",lineHeight:"1.3"}}>Tiết kiệm đến 25% với Veteran's Advantage</p>
                                        <a href='#' className='link-avis'><b>TÌM HIỂU THÊM</b></a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            <div className="row mt-4 mb-2">
              <div className="col-sm-12" style={{ position: "relative" }}>
                <img
                  className="img-fluid w-100"
                  src={publicUrl + "assets/images/resources/img-10.webp"}
                  alt="img-10"
                />
                <div className="col-sm-12 p-4" style={bottomRight}>
                  <h2 className="text-black">
                    Tại Avis, chúng tôi kết hợp những chiếc xe tuyệt vời và
                    những địa điểm đến hấp dẫn để tạo ra những hành trình tốt
                    hơn.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Kết thúc Slogan One */}
      </>
    );
  }
}
