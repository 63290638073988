import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import SignModal from "./SignModal";
import ModalSubscribe from "./ModalSubscribe";

const Nav = (props) => {
  const [btnMobile, setBtnMobile] = useState(false);

  // useEffect(() => {
  //   props.setIsMenu(btnMobile);
  // }, [btnMobile]);
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <>
      <div className="main-menu__inner">
        <MobileMenu btnIsMobile={btnMobile} setIsMobile={setBtnMobile} />
        <ul className="main-menu__list myNav">
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `/`}>Đặt chỗ</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/makereservation`}>
                  Đặt chỗ mới
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/view-cancel`}>
                  Xem / Hủy
                </Link>
              </li>
              <li>
                <a
                  href="https://www.avis.com/en/reservation/get-e-receipt"
                  target="_blank"
                  rel="noreferrer"
                >
                  Nhận Hóa đơn điện tử
                </a>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `/`}>Sự kiện và Ưu đãi</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/promotion`}>
                  Khuyến mãi
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `#`}>Địa điểm</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/findlocation`}>
                  Tìm địa điểm
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/airportlocation`}>
                  Địa điểm sân bay hàng đầu
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `/`}>Xe & Dịch vụ</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/carguides`}>
                  Hướng dẫn xe
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/productservice`}>
                  Sản phẩm & Dịch vụ
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/protection`}>
                  Bảo vệ & Bảo hiểm
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/program`}>
                  Chương trình
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `#`}>Trợ giúp</Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/customer-service`}>
                  Dịch vụ khách hàng
                </Link>
              </li>
              <li>
                <a
                  href="https://faq.avis.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Câu hỏi thường gặp
                </a>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/contact-us`}>
                  Liên hệ chúng tôi
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/view-cancel`}>
                  Đặt chỗ/Hủy bỏ
                </Link>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.avis.co.uk/TermsAndCondition"
                  rel="noreferrer"
                >
                  Điều khoản và Điều kiện địa điểm thuê xe
                </a>
              </li>
            </ul>
          </li>
          <li className="dropdown ps-2 border-start">
            <a
              href="https://member.avisworld.com/"
              target="_blank"
              rel="noreferrer"
            >
              Avis Preferred
            </a>
          </li>
          {localStorage.getItem("token") ? (
            <li className="dropdown">
              <a href={publicUrl + "userhome"}>
                <span className="fa fa-user"></span>{" "}
                {localStorage.getItem("name")}
              </a>
            </li>
          ) : (
            <>
              <li className="dropdown">
                <Link to={process.env.PUBLIC_URL + `/register`}>Đăng ký</Link>
              </li>
              <li className="dropdown pe-2 border-end">
                <Link to={process.env.PUBLIC_URL + `#`}>
                  <SignModal />
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default Nav;
