import React from "react";
import { Link } from "react-router-dom";

export default class PS extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Ô tô & Dịch vụ</a>
                    </li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      Chương trình
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mb-4">
              <div className="col-sm-12 text-black">
                <h1 className="text-up text-black my-5">Chương trình</h1>
                <p className="mb-2 text-avis">
                  Kiếm dặm bay hoặc điểm và nhận giảm giá đặc biệt thông qua các
                  đối tác của chúng tôi. Avis cung cấp nhiều chương trình hợp
                  tác với các hãng hàng không, khách sạn và các công ty đối tác
                  được chọn lọc.
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12">
                <h1 className="link-avis mb-1">Qatar Airways</h1>
                <p className="mb-2 text-avis">
                  Thành viên Burgundy có thể nhận được 2 Avios cơ bản cho mỗi
                  USD chi tiêu. Thành viên Silver nhận 2 Avios cơ bản và 1 Avios
                  thưởng cho mỗi USD chi tiêu. Thành viên Gold nhận 2 Avios cơ
                  bản và 2 Avios thưởng cho mỗi USD chi tiêu. Thành viên
                  Platinum nhận 2 Avios cơ bản và 3 Avios thưởng cho mỗi USD chi
                  tiêu.
                </p>
                <p className="mb-2 text-avis">
                  Cung cấp số thẻ thành viên của bạn khi đặt xe và xuất trình
                  thẻ thành viên tại quầy khi nhận xe để tích lũy dặm bay.
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12">
                <h1 className="link-avis mb-1">Singapore Airlines</h1>
                <p className="mb-2 text-avis">
                  Mọi cấp độ thành viên có thể nhận 500 dặm cho mỗi lần thuê xe
                  hợp lệ dựa trên giá thuê giải trí bán lẻ. Cung cấp số thẻ
                  thành viên của bạn khi đặt xe và xuất trình thẻ thành viên tại
                  quầy khi nhận xe để tích lũy dặm bay.
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12">
                <h1 className="link-avis mb-1">Turkish Airlines</h1>
                <p className="mb-2 text-avis">
                  Thành viên Classic, Elite và Garanti Shop & Miles Card có thể
                  nhận được 500 dặm cho mỗi lần thuê xe hợp lệ.
                </p>
                <p className="mb-2 text-avis">
                  Cung cấp số thẻ thành viên của bạn khi đặt xe và xuất trình
                  thẻ thành viên tại quầy khi nhận xe để tích lũy dặm bay.
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12">
                <h1 className="link-avis mb-1">Master Card</h1>
                <p className="mb-2 text-avis">
                  Thành viên World Elite có thể được giảm giá lên đến 35% trên
                  toàn thế giới. Thành viên World, Platinum, Gold có thể được
                  giảm giá lên đến 20% trên toàn thế giới. Thành viên Business &
                  Sberbank 1 có thể được giảm giá lên đến 20% trên toàn thế
                  giới.
                </p>
                <p className="mb-2 text-avis">
                  Cung cấp số thẻ thành viên của bạn khi đặt xe và xuất trình
                  thẻ thành viên tại quầy khi nhận xe để tích lũy dặm bay.
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-12">
                <h1 className="link-avis mb-1">Visa</h1>
                <p className="mb-2 text-avis">
                  Thành viên Infinite, Infinite Privilege & Signature có thể
                  nhận được giảm giá lên đến 35% và nhận quyền lợi từ Avis
                  President’s Club. Thành viên Platinum có thể nhận giảm giá lên
                  đến 20% cùng quyền lợi từ Avis Preferred Plus. Thành viên
                  Platinum Business (chỉ dành cho thẻ Nga) có thể nhận giảm giá
                  lên đến 20% cùng quyền lợi từ Avis Preferred. Thành viên Gold
                  & Rewards có thể nhận giảm giá lên đến 20% cùng quyền lợi từ
                  Avis Preferred Plus. Thành viên Business có thể nhận giảm giá
                  lên đến 20%.
                </p>
                <p className="mb-2 text-avis">
                  Cung cấp số thẻ thành viên của bạn khi đặt xe và xuất trình
                  thẻ thành viên tại quầy khi nhận xe để tích lũy dặm bay.
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
