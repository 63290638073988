import React from "react";
import AD from "../components/avis360detail/AD";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import Title from "../Title";

const Avis360Detail = () => {
  Title("Discover the Avis Inclusive");
  return (
    <>
      <HeaderOne />
      <div className="container py-5">
        <h1 className="link-avis mb-5">
          Khám phá Avis Inclusive - thuê xe mà không có khoản vượt mức!
        </h1>
        <p className="text-dark mb-3">
          Cho dù bạn cần một chiếc xe trong vài ngày, cuối tuần hay 2 tuần, với
          Avis 360° bạn không cần phải lo lắng về việc thuê xe của mình.
        </p>
        <p className="text-dark mb-3">
          Sản phẩm Avis Inclusive bao gồm:
          <ul>
            <li>- Số km không giới hạn</li>
            <li>
              - Bảo vệ Thiệt hại Va chạm Toàn diện - SCDW (không có khoản vượt
              mức)
            </li>
            <li>- Bảo vệ Trộm cắp Toàn diện (STP)</li>
            <li>- Phụ phí Sân bay hoặc Đường sắt</li>
            <li>- Bảo vệ Kính chắn gió*</li>
            <li>- Trách nhiệm Công cộng</li>
            <li>- Phí Đăng ký Xe (VRF)</li>
            <li>- Thuế VAT Địa phương</li>
            <li>- Hỗ trợ Đường bộ Plus (RSN)**</li>
          </ul>
        </p>
        <p className="text-dark mb-3">
          Avis Inclusive có thể được đặt cho các điểm đến sau:
          <ul>
            <li>
              Áo, Belarus, Bỉ, Bosnia, Bulgaria, Croatia, Cộng hòa Séc, Đan
              Mạch, Estonia, Phần Lan, Pháp, Đức, Hungary, Ý, Lào, Latvia,
              Lithuania, Luxembourg, Malta, Montenegro, Na Uy, Ba Lan, Bồ Đào
              Nha, Romania, Serbia, Singapore, Slovenia, Nam Phi, Tây Ban Nha,
              Thụy Điển, Thụy Sĩ, Thổ Nhĩ Kỳ, UAE, Ukraine và Vương quốc Anh.
            </li>
          </ul>
        </p>
        <p className="text-dark mb-3">
          Đặt ngay Avis Inclusive của bạn tại đây
        </p>
        <p className="text-dark mb-3">
          *Bảo vệ Kính chắn gió có thể không được bao gồm tại một số điểm đến.
        </p>
        <p className="text-dark mb-3">
          **Hỗ trợ Đường bộ Plus (RSN) miễn phí được bao gồm cho các điểm đến
          sau:
          <ul>
            <li>1. Áo</li>
            <li>2. Bỉ</li>
            <li>3. Cộng hòa Séc</li>
            <li>4. Pháp (không bao gồm Corsica)</li>
            <li>5. Đức</li>
            <li>6. Ý </li>
            <li>7. Luxembourg</li>
            <li>8. Ba Lan</li>
            <li>9. Bồ Đào Nha</li>
            <li>10. Tây Ban Nha (không bao gồm Canaries)</li>
            <li>11. Thụy Sĩ</li>
            <li>12. Vương quốc Anh</li>
            <li>13. Đan Mạch</li>
            <li>14. Na Uy</li>
            <li>15. Thụy Điển</li>
          </ul>
        </p>
        <p className="text-dark mb-3">
          Đặt gói Avis Inclusive bằng cách sử dụng các số Avis Worldwide
          Discount (AWD) dưới đây:
          <ul>
            <li>Đối với thuê xe từ 1 đến 13 ngày – I017600</li>
            <li>Đối với thuê xe từ 14 đến 27 ngày – I068100</li>
          </ul>
        </p>
        <p className="text-dark mb-3">
          Điều khoản và điều kiện:
          <ul>
            <li>
              - Tất cả các ưu đãi đều phải chịu các phụ phí bắt buộc cho toàn bộ
              thời gian thuê.
            </li>
            <li>
              - Yêu cầu thời gian đặt trước tối thiểu 24 giờ để nhận được ưu
              đãi.
            </li>
            <li>
              - Các khoản giảm giá không áp dụng cho thuê nội địa (ví dụ, thuê
              xe diễn ra trong cùng một quốc gia mà việc đặt xe được thực hiện).
            </li>
            <li>
              - Ưu đãi áp dụng cho tất cả các nhóm xe, tùy thuộc vào tình trạng
              sẵn có tại thời điểm đặt chỗ.
            </li>
            <li>
              - Hạn chế về ưu đãi có thể áp dụng tại một số thời điểm nhất định
              ở một số địa điểm nhất định.
            </li>
            <li>- Giá thay đổi theo mùa và theo địa điểm của Avis.</li>
            <li>- Giá niêm yết trên trang web là giá đã giảm giá.</li>
            <li>
              - Để đủ điều kiện, đặt chỗ phải được thực hiện bằng cách sử dụng
              số Avis Worldwide Discount (AWD) I017600 cho thuê xe từ 1-13 ngày
              & I068100 cho thuê xe từ 14 - 27 ngày
            </li>
            <li>
              - Nếu có số AWD khác trong việc đặt xe, không có giảm giá nào sẽ
              được áp dụng.
            </li>
            <li>
              - Nếu có số AWD khác trong hồ sơ Avis Preferred của khách hàng và
              được sử dụng trong việc đặt xe, không có giảm giá nào sẽ được áp
              dụng và một khi đã đặt, điều này không thể thay đổi.
            </li>
            <li>
              - Người thuê xe phải đáp ứng các yêu cầu tiêu chuẩn về độ tuổi,
              tài xế và tín dụng của Avis.
            </li>
            <li>
              - Các ưu đãi không được sử dụng kết hợp với bất kỳ giảm giá,
              khuyến mãi hoặc ưu đãi nào khác ngoại trừ những ưu đãi có hiệu lực
              trên trang web này và tùy thuộc vào tình trạng sẵn có.
            </li>
            <li>
              - Avis không chịu trách nhiệm về các lỗi đánh máy hoặc thiếu sót.
            </li>
            <li>- Avis có quyền ngừng ưu đãi này bất cứ lúc nào.</li>
            <li>
              - Các điều khoản và điều kiện thuê xe tiêu chuẩn của Avis được áp
              dụng.
            </li>
          </ul>
        </p>
      </div>
      <FooterOne />
    </>
  );
};

export default Avis360Detail;
