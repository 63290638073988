import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="container py-5">
      <h1 className="text-dark">LIÊN HỆ DỊCH VỤ KHÁCH HÀNG AVIS</h1>
      <p className="text-dark">
        Bạn muốn nói chuyện với đại diện dịch vụ khách hàng của Avis? Để được hỗ
        trợ tại Hoa Kỳ, vui lòng gọi <b>1-800-352-7900</b>. Truy cập{" "}
        <Link className="link-avis">Trang Số Điện Thoại Toàn Cầu</Link> để được
        hỗ trợ tại các quốc gia khác. Các liên kết hữu ích cho nhiều nhu cầu
        dịch vụ khách hàng phổ biến được liệt kê dưới đây.
      </p>
    </div>
  );
};

export default Header;
