import React from "react";
import { Link } from "react-router-dom";

export default class CG extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myBg = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/car-guide1.avif)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "250px",
    };
    const myText = {
      color: "#000",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
    };
    return (
      <>
        {/* Bắt đầu Slogan Một */}
        <section className="container-fluid mt-5" style={myBg}>
          <div className="row justify-content-md-center">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
              <h1 className="ms-5 ps-4 text-up text-white">
                Hướng Dẫn Thuê Xe Avis
              </h1>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-3">
              <div className="col-lg-3">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    Hiển thị:
                  </span>
                  <select className="form-control">
                    <option>Tất cả phương tiện</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon2">
                    Xem xe tại:
                  </span>
                  <select className="form-control">
                    <option>Mỹ</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: "rgb(210, 210, 210)" }}>
          <div className="container">
            <div className="row pt-3 pb-3">
              <div className="col-lg-6 col-sm-12 mb-4">
                <div className="col-sm-12" style={{ backgroundColor: "#fff" }}>
                  <div className="row py-4 px-2">
                    <div className="col-sm-6">
                      <p style={{ fontSize: "20px" }}>Minivan</p>
                      <p style={{ fontSize: "15px" }}>
                        Chrysler Pacifica hoặc tương tự{" "}
                      </p>
                    </div>
                    <div className="col-sm-6 text-end">
                      <button className="link-avis">
                        Thông tin phương tiện
                      </button>
                    </div>
                  </div>
                  <div className="row px-2">
                    <img
                      className="col-sm-12"
                      src={publicUrl + "assets/images/resources/cars1.avif"}
                    />
                  </div>
                  <div className="row px-2 pb-3">
                    <div className="col-sm-12 text-center">
                      <a href="#">
                        <button className="btn btn-avis align-self-center">
                          Đặt xe
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* Tương tự cho các khối khác */}
            </div>
          </div>
        </section>

        <section>
          <div className="container pt-3">
            <div className="row">
              <div className="col-sm-12">
                <p
                  className="mb-3 text-up text-black"
                  style={{ fontSize: "25px" }}
                >
                  TÙY CHỌN THUÊ XE CỦA AVIS
                </p>
                <p className="mb-3 text-avis">
                  Không quan trọng tình huống của bạn là gì, bạn có thể tìm thấy
                  các tùy chọn thuê xe giá cả phải chăng tại Avis. Sedan luôn là
                  một lựa chọn tuyệt vời, đặc biệt để tiết kiệm nhiên liệu,
                  nhưng đôi khi bạn cần nhiều không gian chứa đồ hơn, thoải mái
                  hơn, ghế ngồi nhiều hơn, sức mạnh hơn—và còn nhiều hơn thế
                  nữa. Duyệt qua các loại xe thuê như:
                </p>
                <ol className="ps-3 mb-3 text-avis">
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/suv-rental`}
                      className="link-avis"
                    >
                      SUV
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/van-rental`}
                      className="link-avis"
                    >
                      Xe van
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${process.env.PUBLIC_URL}/pickup-truck-rent`}
                      className="link-avis"
                    >
                      Xe tải
                    </Link>
                  </li>
                </ol>
                <p className="mb-3 text-avis">
                  Hãy chọn từ các loại xe thuê khác nhau với sự tự tin khi biết
                  rằng Avis thường xuyên bổ sung các phương tiện mới vào đội xe
                  và bảo trì cẩn thận từng chiếc xe. Avis cung cấp nhiều danh
                  mục xe thuê khác nhau; duyệt qua danh sách Chevrolet của chúng
                  tôi để xem nhiều tùy chọn thú vị có sẵn cho bạn. Tìm các
                  phương tiện tốt nhất trong các lựa chọn thuê xe sang trọng của
                  chúng tôi, thường bao gồm các tính năng cao cấp như ghế ngồi
                  có sưởi và hệ thống thông tin giải trí hiện đại. Các phương
                  tiện có sẵn từ danh sách xe thuê của chúng tôi thay đổi tùy
                  thuộc vào vị trí và ngày đặt trước, nhưng càng đặt sớm, bạn sẽ
                  càng có nhiều tùy chọn hơn. Khám phá các xe có sẵn trong khu
                  vực của bạn bằng cách nhập địa điểm đón, ngày đón và ngày trả
                  xe. Duyệt và đặt xe thuê với Avis trực tuyến ngay hôm nay.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Kết thúc Slogan Một */}
      </>
    );
  }
}
