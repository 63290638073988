import React from "react";
import { Link, useNavigate } from "react-router-dom";

class PSN extends React.Component {
  detail(data) {
    this.props.navigate("/blankpage", {
      state: {
        data: data,
      },
    });
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/productservice.avif)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "250px",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
    };
    return (
      <>
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="text-white">
                  Sản Phẩm và Dịch Vụ Thuê Xe Tại Mỹ và Canada
                </h1>
                <p className="text-white">
                  Tìm hiểu thêm về các sản phẩm của Avis giúp trải nghiệm thuê
                  xe của bạn dễ dàng và thú vị hơn.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-3">
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={
                    process.env.PUBLIC_URL +
                    `/productservice/usa/gps-navigation`
                  }
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">Hệ Thống Định Vị GPS</h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      Hệ thống định vị GPS giúp bạn tìm thấy mọi điểm đến dễ
                      dàng. Tìm kiếm địa chỉ và điểm tham quan chỉ với một nút
                      bấm trên thiết bị GPS của xe thuê. Kết nối với thiết bị di
                      động của bạn qua bluetooth để gọi điện thoại rảnh tay.
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">Tìm Hiểu Thêm</label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={
                    process.env.PUBLIC_URL + `/productservice/usa/avis-e-toll`
                  }
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">Avis e-Toll</h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      Tìm hiểu thêm về các dịch vụ đặc biệt có sẵn khi bạn thuê
                      xe với Avis. Từ Avis Preferred đến Avis Cares, chúng tôi
                      cung cấp nhiều tùy chọn bổ sung.
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">Tìm Hiểu Thêm</label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={process.env.PUBLIC_URL + `/productservice/usa/fuel-plans`}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <h5 className="mb-2 text-black">
                      Các Kế Hoạch Nhiên Liệu Thuê Xe
                    </h5>
                    <label
                      className="text-avis"
                      style={{ minHeight: "150px", cursor: "pointer" }}
                    >
                      Avis cung cấp nhiều tùy chọn kế hoạch nhiên liệu tiện lợi
                      cho thuê xe. Thanh toán trước cho một bình xăng đầy và trả
                      xe khi bình rỗng hoặc chỉ cần mua đầy bình trước khi trả
                      xe tại điểm trả.
                    </label>
                  </div>
                  <div className="col-12 pb-3 pe-3 text-end">
                    <label className="link-avis">Tìm Hiểu Thêm</label>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

function PS(props) {
  let navigate = useNavigate();
  return <PSN {...props} navigate={navigate} />;
}

export default PS;
