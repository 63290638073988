import React from "react";
import { Link } from "react-router-dom";

export default class DealsOne extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/us-offer1.webp)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
    const myIsi = {
      maxWidth: "30%",
      margin: "auto",
    };
    const myNav = {
      backgroundColor: "rgb(237, 85, 5)",
    };
    return (
      <>
        {/* Bắt đầu Slogan One */}
        <section>
          <div className="container mt-3">
            <a href="#" className="row" style={myStyle}>
              <div className="col-xl-12 text-center pt-5 pb-5" style={myIsi}>
                <img
                  src={publicUrl + "assets/images/resources/us-offer.avif"}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </a>
          </div>
          <div className="container">
            <div className="row mt-4 border-bottom pb-3">
              <div className="col-lg-12 col-sm-12 ps-0">
                <h3 className="text-black">ƯU ĐÃI THUÊ XE TỐT NHẤT</h3>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              <div className="col-xl-12 px-0">
                <div className="row">
                  <div
                    className="col-xl-4 col-lg-4 wow fadeInUp"
                    data-wow-delay=".3s"
                  >
                    <div className="blog-one__single">
                      <div className="blog-one__single-img">
                        <img
                          src={
                            publicUrl +
                            "assets/images/resources/avis-360-feature.jpg"
                          }
                          alt=""
                        />
                      </div>

                      <div className="blog-one__single-content text-center">
                        <p className="blog-one__single-content-tagline text-black">
                          Avis Inclusive - Thuê xe với mức miễn giảm hoặc miễn
                          giảm hoàn toàn.
                        </p>
                        <div className="text" style={{ minHeight: "100px" }}>
                          <p>
                            Cho dù bạn cần một chiếc xe trong vài ngày, cuối
                            tuần hay 2 tuần, với Avis Inclusive, bạn không phải
                            lo lắng về việc thuê xe của mình.
                          </p>
                        </div>
                        <Link
                          to={
                            process.env.PUBLIC_URL + `/promotion/avis360detail`
                          }
                          className="btn btn-avis"
                        >
                          Tiết kiệm ngay
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 col-lg-4 wow fadeInUp"
                    data-wow-delay=".3s"
                  >
                    <div className="blog-one__single">
                      <div className="blog-one__single-img">
                        <img
                          src={
                            publicUrl + "assets/images/resources/us-offer2.avif"
                          }
                          alt=""
                        />
                      </div>

                      <div className="blog-one__single-content text-center">
                        <p className="blog-one__single-content-tagline text-black">
                          Tiết kiệm đến 10% cho thuê xe từ 5 ngày trở lên{" "}
                        </p>
                        <div className="text" style={{ minHeight: "100px" }}>
                          <p>
                            Tin tưởng vào các xe thuê sạch sẽ và quy trình thuê
                            xe an toàn của chúng tôi.
                          </p>
                        </div>
                        <button className="btn btn-avis">Tiết kiệm ngay</button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 col-lg-4 wow fadeInUp"
                    data-wow-delay=".3s"
                  >
                    <div className="blog-one__single">
                      <div className="blog-one__single-img">
                        <img
                          src={
                            publicUrl + "assets/images/resources/us-offer3.avif"
                          }
                          alt=""
                        />
                      </div>

                      <div className="blog-one__single-content text-center">
                        <p className="blog-one__single-content-tagline text-black">
                          Tiết kiệm đến 10% cho thuê xe từ 5 ngày trở lên{" "}
                        </p>
                        <div className="text" style={{ minHeight: "100px" }}>
                          <p>
                            Tin tưởng vào các xe thuê sạch sẽ và quy trình thuê
                            xe an toàn của chúng tôi.
                          </p>
                        </div>
                        <button className="btn btn-avis">Tiết kiệm ngay</button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 col-lg-4 wow fadeInUp"
                    data-wow-delay=".3s"
                  >
                    <div className="blog-one__single">
                      <div className="blog-one__single-img">
                        <img
                          src={
                            publicUrl + "assets/images/resources/us-offer3.avif"
                          }
                          alt=""
                        />
                      </div>

                      <div className="blog-one__single-content text-center">
                        <p className="blog-one__single-content-tagline text-black">
                          Tiết kiệm đến 10% cho thuê xe từ 5 ngày trở lên{" "}
                        </p>
                        <div className="text" style={{ minHeight: "100px" }}>
                          <p>
                            Tin tưởng vào các xe thuê sạch sẽ và quy trình thuê
                            xe an toàn của chúng tôi.
                          </p>
                        </div>
                        <button className="btn btn-avis">Tiết kiệm ngay</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mt-3">
              <div className="col-sm-12 text-avis">
                <p>
                  Ai lại không thích tiết kiệm tiền, đặc biệt khi số tiền đó có
                  thể dùng để mua quà lưu niệm hoặc cho một bữa tối? Avis hiểu
                  rằng việc đi lại có thể tốn kém, và để giúp bạn tận dụng tối
                  đa ngân sách du lịch của mình, chúng tôi đã cung cấp nhiều ưu
                  đãi thuê xe và giảm giá cho các loại xe thuê đã rất phải
                  chăng. Dù bạn muốn có các chương trình khuyến mãi thuê xe đáng
                  tin cậy mà bạn có thể sử dụng nhiều lần hay những ưu đãi thuê
                  xe vào phút chót cho các chuyến đi cuối tuần, bạn chắc chắn sẽ
                  tìm thấy các phiếu giảm giá thuê xe Avis phù hợp với nhu cầu
                  di chuyển của mình. Khi bạn đặt chỗ, các khoản tiết kiệm sẽ tự
                  động được áp dụng và hiển thị trong phần tóm tắt giá của bạn.
                  Một số ưu đãi thuê xe không áp dụng cho tất cả các nhóm xe, và
                  có thể có ngày không áp dụng hoặc hạn chế. Đặt một chiếc xe
                  ngay hôm nay và tiết kiệm cho chuyến đi sắp tới của bạn với
                  các phiếu giảm giá thuê xe của Avis.
                </p>
                <p className="mt-3">
                  Các ưu đãi thuê xe của chúng tôi không chỉ dừng lại ở biên
                  giới Hoa Kỳ, vì vậy hãy thoải mái tận dụng các khuyến mãi cạnh
                  tranh của chúng tôi trong chuyến đi quốc tế tiếp theo của bạn.
                  Khám phá các ưu đãi và phiếu giảm giá cho thuê xe tại các khu
                  vực khác nhau trên thế giới, chẳng hạn như Châu Á, Úc, Canada,
                  Châu Âu, Mỹ Latinh và New Zealand. Tại sao không tiết kiệm cho
                  cả trải nghiệm du lịch của bạn? Kết hợp các khoản giảm giá cho
                  thành viên Ưu tiên với các chương trình khuyến mãi khác từ các
                  đối tác của chúng tôi giúp bạn tận hưởng những khách sạn tốt
                  nhất và các điểm du lịch hàng đầu. Tất nhiên, chúng tôi không
                  thể quên ưu đãi cho các thành viên AARP trung thành của chúng
                  tôi. Và như một lời cảm ơn, luôn có những ưu đãi dành cho các
                  thành viên quân đội đang hoạt động và cựu chiến binh.
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 text-avis">
                <h2 className="text-black">
                  CÂU HỎI THƯỜNG GẶP VỀ ƯU ĐÃI THUÊ XE
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        style={{ textTransform: "uppercase" }}
                      >
                        Làm thế nào để nhận ưu đãi tốt nhất khi thuê xe?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body text-avis">
                        Có một số cách khác nhau để nhận ưu đãi tốt nhất khi
                        thuê xe. Đầu tiên, hãy cuộn đến tab "Ưu đãi" của chúng
                        tôi, để nhận các phiếu giảm giá thuê xe Avis. Khi bạn ở
                        trên trang "Ưu đãi tại Hoa Kỳ", bạn sẽ tìm thấy nhiều
                        phiếu giảm giá và chương trình khuyến mãi thuê xe. Một
                        cách khác để tiết kiệm cho việc thuê xe sắp tới của bạn
                        là tận dụng Cam kết Giá Tốt nhất của Avis. Cam kết Giá
                        Tốt nhất của Avis là lời hứa của chúng tôi rằng chúng
                        tôi sẽ khớp với giá thuê Avis thấp hơn trên các trang
                        web của bên thứ ba đồng thời cung cấp cho bạn thêm chiết
                        khấu.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                        style={{ textTransform: "uppercase" }}
                      >
                        Làm thế nào để tìm xe thuê rẻ nhất?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body text-avis">
                        Khi tìm kiếm các chương trình khuyến mãi thuê xe và các
                        cách khác để giảm chi phí thuê, có ba mẹo chính cần ghi
                        nhớ. <br />
                        1. Thanh toán Ngay: Avis cung cấp cho bạn tùy chọn
                        "Thanh toán Ngay" để bạn có thể thanh toán chi phí thuê
                        khi đặt chỗ thay vì khi nhận xe. Khi bạn chọn thanh toán
                        ngay thay vì sau này, bạn có thể tiết kiệm tới 30% chi
                        phí thuê xe. <br />
                        2. So sánh Giá: Để so sánh giá của các loại xe khác
                        nhau, bạn sẽ cần điền vào biểu mẫu đặt chỗ và nhấp vào
                        "Chọn Xe của Tôi." Sau khi bạn đã nhập ngày và địa điểm
                        du lịch của mình và gửi biểu mẫu, bạn sẽ có thể cuộn qua
                        các tùy chọn thuê xe có sẵn và giá cả. Các loại xe nhỏ
                        hơn, như mẫu xe hạng phổ thông, thường có giá rẻ hơn.
                        Bạn cũng có thể quay lại và thay đổi ngày và địa điểm
                        trên biểu mẫu đặt chỗ để xem giá tăng hay giảm. <br />
                        3. Lên Kế hoạch Trước: Thông thường giá thuê xe sẽ tăng
                        lên khi ngày đặt xe gần kề. Đặt trước để khóa giá của
                        bạn. Thuê xe khứ hồi cũng có xu hướng rẻ hơn, vì vậy hãy
                        ghi nhớ điều đó khi lập kế hoạch du lịch.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        style={{ textTransform: "uppercase" }}
                      >
                        Thời gian nào trong ngày là tốt nhất để thuê xe?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body text-avis">
                        Xe thuê của Avis luôn phụ thuộc vào sự sẵn có của xe và
                        tốt nhất là bạn nên gọi trước cho địa điểm của mình nếu
                        không có đặt chỗ, nhưng nhìn chung lựa chọn xe lớn nhất
                        là ngay khi Avis mở cửa. Trước khi bạn đến, hãy kiểm tra
                        văn phòng Avis địa phương của bạn để biết giờ thuê xe.
                        Bạn luôn có thể đặt xe vào cùng ngày, miễn là trước ít
                        nhất một giờ so với thời gian nhận xe của bạn.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                        style={{ textTransform: "uppercase" }}
                      >
                        Thuê xe trực tuyến hay trực tiếp rẻ hơn?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body text-avis">
                        Mặc dù giá thuê xe thay đổi từ ngày này sang ngày khác,
                        bạn sẽ thường thấy các ưu đãi tốt hơn nếu bạn thuê xe
                        trực tuyến thay vì trực tiếp. Hầu hết các ưu đãi đặc
                        biệt về thuê xe của chúng tôi chỉ có sẵn trực tuyến và
                        nếu bạn chọn "Thanh toán Ngay" khi đặt chỗ trực tuyến,
                        bạn có thể tiết kiệm tới 30% giá thuê xe. Tuy nhiên, đôi
                        khi bạn có thể tìm thấy ưu đãi thuê xe vào phút chót khi
                        thuê trực tiếp.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                        style={{ textTransform: "uppercase" }}
                      >
                        Thuê xe tại sân bay hay bên ngoài sân bay rẻ hơn?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body text-avis">
                        Khi so sánh giá trực tiếp, đôi khi thuê xe bên ngoài sân
                        bay có thể rẻ hơn thuê xe tại sân bay. Tuy nhiên, nếu
                        bạn tính đến các chi phí khác, như taxi hoặc dịch vụ xe
                        đưa đón, chúng có thể có giá tương đương. Đối với những
                        ai muốn thuê xe từ Avis tại sân bay, hãy tìm các phiếu
                        giảm giá và ưu đãi để có thêm lợi thế.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Kết thúc Slogan One */}
      </>
    );
  }
}
