import React from "react";
import { Link } from "react-router-dom";

const CS = () => {
  return (
    <>
      <section className="border-top border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb text-avis py-3">
                  <li className="breadcrumb-item">Ô tô & Dịch vụ</li>
                  <li className="breadcrumb-item">Sản phẩm & Dịch vụ</li>
                  <li className="breadcrumb-item link-avis" aria-current="page">
                    Ghế Trẻ Em
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-2">
          <div className="row py-3">
            <div className="col-12">
              <p className="text-avis mt-2">
                Avis cung cấp nhiều loại ghế an toàn chất lượng cao cho bé, trẻ
                nhỏ và trẻ em phù hợp với các thành viên nhỏ tuổi trong gia đình
                bạn.
              </p>
              <p className="text-avis mt-2">
                Quy định về ghế trẻ em và các tùy chọn ghế khác nhau tùy theo
                quốc gia. Chúng tôi khuyến nghị bạn kiểm tra{" "}
                <a
                  href="https://www.avis.co.uk/TermsAndCondition"
                  target="_blank"
                  rel="noreferrer"
                  className="link-avis"
                >
                  Điều khoản và Điều kiện thuê xe
                </a>{" "}
                để biết thêm chi tiết.
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <p className="text-avis mt-2">
                <b>Nói chung -</b>
              </p>
              <p className="text-avis mt-2">
                Ghế cho bé thường phù hợp với trẻ từ sơ sinh đến 12 tháng tuổi
                và nặng không quá 13 kg.
              </p>
              <p className="text-avis mt-2">
                Ghế cho trẻ nhỏ thường phù hợp với trẻ từ 9 tháng đến 4 tuổi và
                nặng từ 9 đến 18 kg.
              </p>
              <p className="text-avis mt-2">
                Ghế cho trẻ em thường phù hợp với trẻ từ 4 đến 11 tuổi và nặng
                từ 15 đến 36 kg. Ghế nâng thường phù hợp với trẻ từ 8 đến 11
                tuổi và nặng từ 20 đến 40 kg.
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <p className="text-avis mt-2">
                Xin lưu ý rằng các giải thích trên có thể không giống nhau ở tất
                cả các quốc gia.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CS;
