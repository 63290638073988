import React from "react";
import { Link } from "react-router-dom";

export default class Pro extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      // backgroundImage: "url("+publicUrl+"assets/images/resources/productservice.avif)",
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      // height: "200px"
    };
    const myHead = {
      fontSize: "18px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`${process.env.PUBLIC_URL}/protection`}>
                        Sản phẩm & Dịch vụ
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      Bảo vệ & Bảo hiểm
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section style={myStyle}>
          <div className="container pt-3">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="text-black text-up pb-2">Bảo vệ & Bảo hiểm</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-4" style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-4">
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/protection/detail/Extended-Roadside-Assistance`,
                  }}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <label className="mb-2" style={myHead}>
                      <div className="row" style={{ cursor: "pointer" }}>
                        <div className="col-sm-3" style={{ cursor: "pointer" }}>
                          <img
                            className="img-fluid"
                            src={publicUrl + "assets/images/resources/RSN.avif"}
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className="col-sm-9 align-self-center text-black"
                          style={{ cursor: "pointer" }}
                        >
                          Hỗ trợ Đường cao tốc Mở rộng
                        </div>
                      </div>
                    </label>
                    <label
                      className="text-avis mt-2 text-black"
                      style={{ cursor: "pointer" }}
                    >
                      Để có trải nghiệm lái xe an toàn tối ưu, hãy thêm Dịch vụ
                      Hỗ trợ Đường cao tốc Mở rộng vào đơn đặt xe của bạn tại
                      Avis. Dịch vụ Hỗ trợ Đường cao tốc Mở rộng từ Avis sẽ cung
                      cấp cho bạn hỗ trợ nhanh chóng và đáng tin cậy trong các
                      tình huống khẩn cấp.
                    </label>
                  </div>
                  <div className="col-12 p-3 text-end">
                    <label className="link-avis" style={{ cursor: "pointer" }}>
                      Tìm hiểu thêm{" "}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/protection/detail/Loss-Damage-Waiver-(LDW)`,
                  }}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <label className="mb-2" style={myHead}>
                      <div className="row" style={{ cursor: "pointer" }}>
                        <div className="col-sm-3" style={{ cursor: "pointer" }}>
                          <img
                            className="img-fluid"
                            src={
                              publicUrl +
                              "assets/images/resources/protection-ldw.avif"
                            }
                            alt=""
                          />
                        </div>
                        <div
                          className="col-sm-9 align-self-center text-black"
                          style={{ cursor: "pointer" }}
                        >
                          Miễn Trách nhiệm Hư hỏng (LDW)
                        </div>
                      </div>
                    </label>
                    <label
                      className="text-avis mt-2 text-black"
                      style={{ cursor: "pointer" }}
                    >
                      Avis cung cấp LDW, một tùy chọn giúp bạn không phải chịu
                      trách nhiệm tài chính nếu xe thuê bị hư hỏng hoặc bị mất
                      cắp.
                    </label>
                  </div>
                  <div className="col-12 p-3 text-end">
                    <label className="link-avis" style={{ cursor: "pointer" }}>
                      Tìm hiểu thêm{" "}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/protection/detail/Personal-Accident-Insurance-(PAI)`,
                  }}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <label className="mb-2" style={myHead}>
                      <div className="row" style={{ cursor: "pointer" }}>
                        <div className="col-sm-3" style={{ cursor: "pointer" }}>
                          <img
                            className="img-fluid"
                            src={publicUrl + "assets/images/resources/PAI.png"}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-sm-9 align-self-center text-black"
                          style={{ cursor: "pointer" }}
                        >
                          Bảo hiểm Tai nạn Cá nhân (PAI)
                        </div>
                      </div>
                    </label>
                    <label
                      className="text-avis mt-2 text-black"
                      style={{ cursor: "pointer" }}
                    >
                      Bảo vệ bạn và hành khách của bạn với bảo hiểm và chi trả y
                      tế, trong trường hợp tai nạn xảy ra.
                    </label>
                  </div>
                  <div className="col-12 p-3 text-end">
                    <label className="link-avis" style={{ cursor: "pointer" }}>
                      Tìm hiểu thêm{" "}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/protection/detail/Personal-Effect-Insurance-(PEP)`,
                  }}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <label className="mb-2" style={myHead}>
                      <div className="row" style={{ cursor: "pointer" }}>
                        <div className="col-sm-3" style={{ cursor: "pointer" }}>
                          <img
                            className="img-fluid"
                            src={publicUrl + "assets/images/resources/PEP.png"}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-sm-9 align-self-center text-black"
                          style={{ cursor: "pointer" }}
                        >
                          Bảo hiểm Hiệu lực Cá nhân (PEP)
                        </div>
                      </div>
                    </label>
                    <label
                      className="text-avis mt-2 text-black"
                      style={{ cursor: "pointer" }}
                    >
                      Bảo hiểm đồ dùng cá nhân của bạn trong suốt chuyến đi.
                    </label>
                  </div>
                  <div className="col-12 p-3 text-end">
                    <label className="link-avis" style={{ cursor: "pointer" }}>
                      Tìm hiểu thêm{" "}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/protection/detail/Additional-Liability-Insurance-(ALI)`,
                  }}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <label className="mb-2" style={myHead}>
                      <div className="row" style={{ cursor: "pointer" }}>
                        <div className="col-sm-3" style={{ cursor: "pointer" }}>
                          <img
                            className="img-fluid"
                            src={publicUrl + "assets/images/resources/ALI.avif"}
                            alt=""
                          />
                        </div>
                        <div
                          className="col-sm-9 align-self-center text-black"
                          style={{ cursor: "pointer" }}
                        >
                          Bảo hiểm Trách nhiệm Bổ sung (ALI)
                        </div>
                      </div>
                    </label>
                    <label
                      className="text-avis mt-2 text-black"
                      style={{ cursor: "pointer" }}
                    >
                      ALI là bảo hiểm trách nhiệm ô tô bổ sung bảo vệ bạn và tất
                      cả tài xế được ủy quyền khỏi các khiếu nại về thương tích
                      cơ thể và hư hỏng tài sản của bên thứ ba.
                    </label>
                  </div>
                  <div className="col-12 p-3 text-end">
                    <label className="link-avis" style={{ cursor: "pointer" }}>
                      Tìm hiểu thêm{" "}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/protection/detail/Emergency-Sickness-Plan-(ESP)`,
                  }}
                  className="row"
                  style={myCard}
                >
                  <div className="col-12 p-3">
                    <label className="mb-2" style={myHead}>
                      <div className="row" style={{ cursor: "pointer" }}>
                        <div className="col-sm-3" style={{ cursor: "pointer" }}>
                          <img
                            className="img-fluid"
                            src={
                              publicUrl +
                              "assets/images/resources/ESP_Icon.avif"
                            }
                            alt=""
                          />
                        </div>
                        <div
                          className="col-sm-9 align-self-center text-black"
                          style={{ cursor: "pointer" }}
                        >
                          Kế hoạch Ốm đau Khẩn cấp (ESP)
                        </div>
                      </div>
                    </label>
                    <label
                      className="text-avis mt-2 text-black"
                      style={{ cursor: "pointer" }}
                    >
                      ESP cung cấp bảo hiểm cho người thuê quốc tế khi đến Hoa
                      Kỳ với bảo hiểm chi phí y tế phát sinh do ốm đau.
                    </label>
                  </div>
                  <div className="col-12 p-3 text-end">
                    <label className="link-avis" style={{ cursor: "pointer" }}>
                      Tìm hiểu thêm{" "}
                    </label>
                  </div>
                </Link>
              </div>
            </div>

            <div className="container flex row pt-4">
              <hr className="col-sm-12" />
            </div>
            <div className="row pt-4">
              <h4 className="text-up text-black fw-normal">
                Đối với thuê xe ở các nơi khác trên thế giới.
              </h4>
              <p className="">
                Để có sự yên tâm hoàn toàn trong suốt thời gian thuê xe của bạn,
                chúng tôi cung cấp nhiều gói bảo hiểm bổ sung để giảm bớt khoản
                khấu trừ của bạn, hoặc để bảo vệ và hỗ trợ bạn trong trường hợp
                xảy ra tai nạn hoặc hỏng xe.
              </p>
              <p className="">
                Tại quầy cho thuê, bạn có thể thêm gói bảo hiểm bổ sung cho thuê
                xe của mình.
              </p>
            </div>
            <div className="col-12 mt-4 p-3" style={myCard}>
              <label className="mb-2">
                <div className="row">
                  <div className="col-sm-12 align-self-center text-black">
                    Miễn Trách nhiệm Hư hỏng (CDW) và Miễn Trách nhiệm Hư hỏng
                    Siêu cấp (SCDW)
                  </div>
                </div>
              </label>
              <label className="text-avis mt-2 text-black">
                CDW giảm khấu trừ của bạn, có nghĩa là nếu có thiệt hại mới và
                không do sơ suất đối với thân xe, bạn sẽ chỉ phải trả một khoản
                chi phí giảm đáng kể, nếu có. SCDW giảm khoản khấu trừ thêm nữa,
                ở một số quốc gia, khoản khấu trừ là bằng không. Khi được chọn,
                sản phẩm này sẽ được thêm vào hợp đồng thuê xe của bạn ngay từ
                đầu với các chi phí liên quan đã được báo giá trước. Khoản này
                sẽ được tính vào thẻ thanh toán của bạn khi kết thúc thời gian
                thuê cùng với các khoản phí thuê khác. Các điều khoản và điều
                kiện địa phương áp dụng.
              </label>
            </div>
            <div className="col-12 mt-4 p-3" style={myCard}>
              <label className="mb-2">
                <div className="row">
                  <div className="col-sm-12 align-self-center text-black">
                    Bảo vệ Chống Trộm (TP) và Bảo vệ Chống Trộm Siêu cấp (STP)
                  </div>
                </div>
              </label>
              <label className="text-avis mt-2 text-black">
                TP giảm khấu trừ của bạn, có nghĩa là nếu xe thuê của bạn bị mất
                cắp, bạn sẽ chỉ phải trả một khoản chi phí giảm đáng kể, nếu có.
                STP giảm khoản khấu trừ thêm nữa, ở một số quốc gia, khoản khấu
                trừ là bằng không. Kiểm tra số tiền khấu trừ được ghi trên hợp
                đồng thuê của bạn để biết số tiền tối đa mà người lái xe sẽ phải
                chịu trách nhiệm trong trường hợp xe bị mất cắp. Khi được chọn,
                sản phẩm này sẽ được thêm vào hợp đồng thuê xe của bạn ngay từ
                đầu với các chi phí liên quan đã được báo giá trước. Khoản này
                sẽ được tính vào thẻ thanh toán của bạn khi kết thúc thời gian
                thuê cùng với các khoản phí thuê khác. Các điều khoản và điều
                kiện địa phương áp dụng.
              </label>
            </div>
            <div className="col-12 mt-4 p-3" style={myCard}>
              <label className="mb-2">
                <div className="row">
                  <div className="col-sm-12 align-self-center text-black">
                    Hỗ trợ Đường cao tốc Nâng cao (RSN)
                  </div>
                </div>
              </label>
              <label className="text-avis mt-2 text-black">
                RSN là sản phẩm Lưới An toàn Đường cao tốc của chúng tôi. Ngoài
                việc hỗ trợ hỏng hóc tiêu chuẩn đã bao gồm trong hợp đồng thuê
                của bạn, dịch vụ Hỗ trợ Đường cao tốc Nâng cao của chúng tôi
                (hoặc Dịch vụ Hỗ trợ Đường cao tốc Cao cấp) bao gồm bất kỳ vấn
                đề không cơ khí nào mà bạn có thể gặp phải. Sản phẩm này loại bỏ
                các chi phí phụ phát sinh có thể liên quan đến sự cố xe, chẳng
                hạn như khởi động lại hoặc phí kéo xe. Khi được chọn, RSN sẽ
                được đưa vào hợp đồng thuê xe của bạn ngay từ đầu. Khoản này sẽ
                được tính vào thẻ thanh toán của bạn khi kết thúc thời gian thuê
                cùng với các khoản phí thuê xe còn lại. Các điều khoản và điều
                kiện địa phương áp dụng.
              </label>
            </div>
            <div className="col-12 mt-4 p-3" style={myCard}>
              <label className="mb-2">
                <div className="row">
                  <div className="col-sm-12 align-self-center text-black">
                    Bảo hiểm Tai nạn Cá nhân (PAI) hoặc Bảo hiểm Tai nạn Cá nhân
                    Siêu cấp (SPAI)
                  </div>
                </div>
              </label>
              <label className="text-avis mt-2 text-black">
                PAI cung cấp cho bạn bảo hiểm bổ sung trong trường hợp xảy ra
                tai nạn, bao gồm chăm sóc y tế khẩn cấp, vận chuyển, hành lý bị
                mất và chi trả cho các chi phí bất ngờ. Chúng tôi đã hợp tác với
                các công ty bảo hiểm tại quốc gia cho thuê để cung cấp cho bạn
                và hành khách của bạn sự bảo vệ hàng đầu về chấn thương cá nhân
                và hỗ trợ và vận chuyển y tế khẩn cấp.
              </label>
            </div>
            <div className="col-12 mt-4 p-3" style={myCard}>
              <label className="mb-2">
                <div className="row">
                  <div className="col-sm-12 align-self-center text-black">
                    Bảo hiểm Kính chắn gió (WDW)
                  </div>
                </div>
              </label>
              <label className="text-avis mt-2 text-black">
                Bảo vệ Kính chắn gió giảm khoản khấu trừ kính chắn gió của bạn
                xuống bằng không, có nghĩa là nếu có thiệt hại mới và không do
                sơ suất đối với kính chắn gió của xe, bạn sẽ chỉ phải trả một
                khoản chi phí giảm đáng kể, nếu có. Khi được chọn, sản phẩm này
                sẽ được thêm vào hợp đồng thuê xe của bạn ngay từ đầu với các
                chi phí liên quan đã được báo giá trước. Khoản này sẽ được tính
                vào thẻ thanh toán của bạn khi kết thúc thời gian thuê cùng với
                các khoản phí thuê khác.
              </label>
            </div>
          </div>
        </section>
      </>
    );
  }
}
