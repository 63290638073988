import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatRupiah, getToken } from "../../core/functions";
import { API_URL } from "../../core/constant";
import HeaderAlt from "../../common/header/HeaderAlt";
import FooterOne from "../../common/footer/FooterOne";
import { useLocation } from "react-router-dom";
import { generateRandomNumbers } from "../../core/functions";
import { Link } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";
import { FaCheck } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { format } from "date-fns";

const BD = (props) => {
  const [header, setHeader] = useState([]);
  const [detail, setDetail] = useState([]);
  const [midLink, setMidLink] = useState("");
  const [pickupLat, setPickupLat] = useState();
  const [pickupLng, setPickupLng] = useState();
  const [dropoffLat, setDropoffLat] = useState();
  const [dropoffLng, setDropoffLng] = useState();

  const [currentLatitude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");
  const [errorGetCurrentLocation, setErrorGetCurrentLocation] = useState("");

  const {
    booking,
    requestTime,
    transactionId,
    pickupNameLocation,
    dropoffNameLocation,
    pickupCityLocation,
    dropoffCityLocation,
    pickupCodeLocation,
    dropoffCodeLocation,
    pickupAddress1Location,
    dropoffAddress1Location,
    pickupAddress2Location,
    dropoffAddress2Location,
    pickupPostalCodeLocation,
    dropoffPostalCodeLocation,
    pickupTelephoneLocation,
    dropoffTelephoneLocation,
    pickupCoordinate,
    dropoffCoordinate,
    pickupDate,
    dropoffDate,
    vehicle,
    couponCode,
    make,
    model,
    name,
    image,
    telephone,
    email,
    age,
    address_line_1,
    address_line_2,
    city,
    postal_code,
    lastName,
    firstName,
    bookingNumber,
    paymentType,
    priceIdr,
    taxIdr,
    totalIdr,
    total,
    extraData,
  } = props.before;

  console.log(props.before);

  localStorage.setItem("bookingNumber", bookingNumber);
  localStorage.setItem("pickup_location", pickupCodeLocation);
  localStorage.setItem("dropoff_location", dropoffCodeLocation);
  localStorage.setItem("pickup_date", pickupDate);
  localStorage.setItem("dropoff_date", dropoffDate);
  localStorage.setItem("total_price", totalIdr);
  localStorage.setItem("location", pickupNameLocation);
  localStorage.setItem("pickupLat", pickupCoordinate[0]);
  localStorage.setItem("pickupLng", pickupCoordinate[1]);
  localStorage.setItem("dropoffLat", dropoffCoordinate[0]);
  localStorage.setItem("dropoffLng", dropoffCoordinate[1]);
  localStorage.setItem("requestTime", requestTime);
  localStorage.setItem("currentLatitude", currentLatitude);
  localStorage.setItem("currentLongitude", currentLongitude);
  localStorage.setItem("nameLocation", pickupNameLocation);
  localStorage.setItem("cityLocation", pickupCityLocation);
  localStorage.setItem("codeLocation", pickupCodeLocation);
  localStorage.setItem("addressLine1Location", pickupAddress1Location);
  localStorage.setItem("addressLine2Location", pickupAddress2Location);
  localStorage.setItem("postalCodeLocation", pickupPostalCodeLocation);
  localStorage.setItem("telephoneLocation", pickupTelephoneLocation);
  const extra = JSON.stringify(extraData);
  localStorage.setItem("totalIdr", totalIdr);
  localStorage.setItem("extraData", extra);
  localStorage.setItem("make", vehicle.category.make);
  localStorage.setItem("image", vehicle.category.image_url);
  localStorage.setItem("model", vehicle.category.model);
  localStorage.setItem("vehicle_sipp_code", vehicle.category.sipp_code);
  localStorage.setItem("transmission", vehicle.category.vehicle_transmission);
  localStorage.setItem("rate_code", vehicle.rate_totals.rate.rate_code);
  localStorage.setItem("amount", total);
  localStorage.setItem("last_name", lastName);
  localStorage.setItem("first_name", firstName);
  localStorage.setItem("email", email);
  localStorage.setItem("telephone", telephone);
  localStorage.setItem("age", age);
  localStorage.setItem("address_line_1", address_line_1);
  localStorage.setItem("address_line_2", address_line_2);
  localStorage.setItem("city", city);
  localStorage.setItem("postal_code", postal_code);

  const navigate = useNavigate();

  const handleCancel = async (book) => {
    const res = await axios.post(`${API_URL}/api/booking/cancel`, {
      booking_code: bookingNumber,
      email: email,
    });

    if (res.status === 200) {
      alert("Đặt chỗ đã được hủy thành công");

      navigate("/makereservation", {
        state: {
          booking: book,
        },
      });
    }

    if (res.status !== 200) {
      alert(res.data.msg);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await axios.post(
          `${API_URL}/api/booking/search`,
          {
            book: booking,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (res.status === 200) {
          setHeader(res.data.booking.header);
          setDetail(res.data.booking.detail);
          setMidLink(res.data.booking.mid);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    if (pickupCoordinate & dropoffCoordinate) {
      setPickupLat(pickupCoordinate[0]);
      setPickupLng(pickupCoordinate[1]);
      setDropoffLat(dropoffCoordinate[0]);
      setDropoffLng(dropoffCoordinate[1]);
    }
  }, [dropoffCoordinate, pickupCoordinate]);

  const successGetCurrentLocation = (position) => {
    setCurrentLatitude(position.coords.latitude);
    setCurrentLongitude(position.coords.longitude);
    setErrorGetCurrentLocation(null);
  };

  const errorMessageGetCurrentLocation = (error) => {
    setErrorGetCurrentLocation(error.message);
  };

  const getPickUpDirectionUrl = () => {
    console.log(currentLatitude, currentLongitude, pickupLat, pickupLng);
    if (currentLatitude && currentLongitude && pickupLat && pickupLng) {
      const currentLocation = `${currentLatitude},${currentLongitude}`;
      const targetLocation = `${pickupLat},${pickupLng}`;
      const url = `https://www.google.com/maps/dir/${currentLocation}/${targetLocation}`;
      return window.open(url, "_blank");
    }
  };

  const getDropOffDirectionUrl = () => {
    if (currentLatitude && currentLongitude && dropoffLat && dropoffLng) {
      const currentLocation = `${currentLatitude},${currentLongitude}`;
      const targetLocation = `${dropoffLat},${dropoffLng}`;
      const url = `https://www.google.com/maps/dir/${currentLocation}/${targetLocation}`;
      return window.open(url, "_blank");
    }
  };

  let publicUrl = process.env.PUBLIC_URL + "/";

  const customIcon = new Icon({
    iconUrl: publicUrl + "assets/images/icon/marker.png",
    iconSize: [38, 38],
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        successGetCurrentLocation,
        errorMessageGetCurrentLocation
      );
    } else {
      setErrorGetCurrentLocation("Trình duyệt này không hỗ trợ định vị địa lý");
    }
  });

  const myLink = {
    color: "rgb(212, 0, 42)",
  };
  const myBorder = {
    borderBottom: "2px solid rgb(212, 0, 42)",
  };
  const myLinkDis = {
    color: "rgb(168, 168, 168)",
  };
  const myBorderDis = {
    borderBottom: "2px solid rgb(168, 168, 168)",
  };
  const selectLink = {
    border: "2px solid rgb(212, 0, 42)",
    color: "#140a55",
  };
  const btnLink = {
    backgroundColor: "rgb(212, 0, 42)",
    color: "rgb(255, 255, 255)",
    padding: "0px 50px",
  };

  return (
    <div>
      {paymentType !== "paylaters" ? (
        <div>
          <section>
            <div className="container">
              <div className="row mt-3">
                <div className="col-sm-6">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Mã thuê xe</td>
                        <td>
                          <b>{booking}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Mã đặt chỗ</td>
                        <td>
                          <b>{bookingNumber}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Trạng thái đặt chỗ</td>
                        <td>
                          <b>{header.status_book}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Đặt chỗ vào</td>
                        <td>
                          <b>{format(requestTime, "MMMM dd, yyyy")}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="col-sm-6 text-end">
                  <a
                    className="btn py-1"
                    href={midLink}
                    target="_blank"
                    style={btnLink}
                    rel="noreferrer"
                  >
                    Thanh toán ngay
                  </a>
                  <br />
                  <button
                    style={btnLink}
                    className="btn py-1 mt-2"
                    onClick={(e) => handleCancel(header.rent_id, bookingNumber)}
                  >
                    Hủy đặt chỗ
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-4">
            <div className="container">
              <div className="row mt-4">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6 border-bottom">
                      <div className="row">
                        <div className="col-sm-6">
                          <p style={myLink}>Đón xe</p>
                          <p>
                            <b>{pickupNameLocation}</b>
                            <br />
                            {pickupCityLocation}, {pickupCodeLocation}
                            <br />
                            {pickupAddress1Location}, {pickupAddress2Location},{" "}
                            {pickupPostalCodeLocation}, {pickupCodeLocation}
                            <br />
                            <b>{pickupTelephoneLocation}</b>
                            <br />
                            <b>Mã địa điểm: </b> {pickupCodeLocation}
                          </p>
                          <p>
                            <b>{format(pickupDate, "HH:mm a MMMM d, yyyy")}</b>
                          </p>
                        </div>
                        <div className="col-sm-6">
                          <p style={myLink}>Trả xe</p>
                          <p>
                            <b>{dropoffNameLocation}</b>
                            <br />
                            {dropoffCityLocation},{dropoffCodeLocation}
                            <br />
                            {dropoffAddress1Location},{dropoffAddress2Location},
                            {dropoffPostalCodeLocation}
                            <br />
                            <b>{dropoffTelephoneLocation}</b>
                            <br />
                            <b>Mã địa điểm: </b> {dropoffCodeLocation}
                          </p>
                          <p>
                            <b>{format(dropoffDate, "HH:mm a MMMM d, yyyy")}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 border-start border-bottom">
                      <div className="row">
                        <div className="col-sm-6">
                          <p>Giá cơ bản</p>
                          <p style={myLink}>Thuế & Phí</p>
                          <p>Tổng dự kiến</p>
                          <p style={myLink}>Xem điều khoản giá</p>
                        </div>
                        <div className="col-sm-6 text-end">
                          <p>IDR {priceIdr.toLocaleString("id")}</p>
                          <p>IDR {taxIdr.toLocaleString("id")}</p>
                          <p>
                            <b>IDR {totalIdr.toLocaleString("id")}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="row mt-2">
                        <div className="col-sm-3">
                          <img
                            alt="category"
                            src={vehicle.category.image_url}
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-sm-9">
                          <p>
                            <h5 className="text-dark">
                              {vehicle.category.name}
                            </h5>
                          </p>
                          <p>
                            {vehicle.category.make} {vehicle.category.model}
                          </p>
                          <p>
                            <img
                              alt="user"
                              src="/assets/images/icon/user.png"
                            />{" "}
                            {vehicle.capacity.seats}
                            <img
                              alt="suitcase"
                              src="/assets/images/icon/suitcase.png"
                              className="ms-2"
                            />{" "}
                            {vehicle.capacity.luggage_capacity.large_suitcase}
                            L
                            <img
                              alt="suitcase"
                              src="/assets/images/icon/suitcase.png"
                              className="ms-2"
                            />{" "}
                            {vehicle.capacity.luggage_capacity.large_suitcase}S
                          </p>
                          <p>{vehicle.category.vehicle_transmission}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 border-start text-end text-success">
                      <p>Thông tin khác</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="container my-5">
          <section id="thank-you-note" className="mt-4">
            <div className="d-flex flex-column w-100 align-items-center text-center grid gap-3">
              <CiCircleCheck style={{ fontSize: "100px", color: "#4fdb6d" }} />
              <div
                style={{ color: "#4fdb6d", fontWeight: "bolder" }}
                className="fs-2"
              >
                CẢM ƠN BẠN.
              </div>
              <div className="fs-5">
                Đặt chỗ của bạn đã hoàn tất. Chúng tôi sẽ gửi email xác nhận cho
                bạn sớm.
              </div>
              <div className="fs-5">Mã tham chiếu của bạn: {bookingNumber}</div>
              <div style={{ fontSize: "18px" }}>
                Vui lòng đọc{" "}
                <Link
                  to={process.env.PUBLIC_URL + `/`}
                  style={{
                    textDecoration: "underline",
                    color: "red",
                  }}
                >
                  điều khoản và điều kiện
                </Link>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default BD;
