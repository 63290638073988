import React from "react";

export default class DP extends React.Component {
  render() {
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb text-avis py-3">
                    <li className="breadcrumb-item">Xe & Dịch vụ</li>
                    <li className="breadcrumb-item">Bảo vệ & Bảo hiểm</li>
                    <li className="breadcrumb-item">Mỹ & Canada</li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      {this.props.data}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container py-5">
            <div className="row">
              {this.props.data === "Extended Roadside Assistance" ? (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    Hỗ Trợ Đường Dài Mở Rộng
                  </h1>
                  <p className="text-avis mt-2">
                    <b>
                      Lên kế hoạch trước để có sự an tâm. Với Dịch Vụ Hỗ Trợ
                      Đường Dài Mở Rộng, bạn có thể yên tâm khi di chuyển. Chúng
                      tôi hiểu rằng đôi khi có thể xảy ra sự cố – đó là thực tế
                      – và chúng tôi sẽ chăm sóc bạn bằng các dịch vụ hỗ trợ bên
                      đường, bao gồm:
                    </b>
                  </p>
                  <ul className="text-avis mt-2">
                    <li>
                      • <b>Dịch vụ mở khóa</b> - Giúp bạn vào xe khi chìa khóa
                      bị khóa bên trong. Nhận chìa khóa thay thế hoặc hỗ trợ thợ
                      khóa.
                    </li>
                    <li>
                      • <b>Hỗ trợ & Thay thế lốp xe bị xì hơi</b> - Chúng tôi sẽ
                      thay lốp bị hư hoặc xì bằng lốp dự phòng, hoặc đưa xe đến
                      cơ sở dịch vụ gần nhất.
                    </li>
                    <li>
                      • <b>Khởi động lại bình</b> - Nếu xe của bạn bị hỏng bình,
                      chúng tôi sẽ cung cấp dịch vụ khởi động lại.
                    </li>
                    <li>
                      • <b>Giao nhiên liệu</b> - Nếu bạn hết nhiên liệu, chúng
                      tôi sẽ giao 3 gallon (ở Mỹ/Canada) hoặc lên đến $10 nhiên
                      liệu (ở Úc/NZ) tới địa điểm của bạn.
                    </li>
                  </ul>
                  <p className="text-avis mt-2">
                    <b>
                      Để có trải nghiệm lái xe an toàn nhất, hãy yêu cầu Dịch Vụ
                      Hỗ Trợ Đường Dài Mở Rộng khi nhận xe tại trạm của chúng
                      tôi.
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    Trong trường hợp có khó khăn về hoạt động hoặc khẩn cấp
                    không mong muốn, bạn muốn có dịch vụ đáng tin cậy một cách
                    nhanh chóng - điều này đặc biệt hữu ích khi bạn ở một thành
                    phố xa lạ. Với Dịch Vụ Hỗ Trợ Đường Dài Mở Rộng, bạn sẽ nhận
                    được sự hỗ trợ nhanh chóng và đáng tin cậy.
                  </p>
                  <p className="text-avis mt-2">
                    Để truy cập Dịch Vụ Hỗ Trợ Đường Dài Mở Rộng 24/7,
                  </p>
                  <ul className="text-avis mt-2">
                    <li>• Ở Mỹ/Canada, gọi 800-354-2847</li>
                    <li>• Ở Úc, gọi 1800 063 973</li>
                    <li>• Ở New Zealand, gọi 0800 652 847</li>
                  </ul>
                  <p className="text-avis mt-2">
                    Dịch Vụ Hỗ Trợ Đường Dài Mở Rộng có thể được mua tại quầy
                    khi nhận xe.
                  </p>
                </div>
              ) : this.props.data === "Loss Damage Waiver (LDW)" ? (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    Miễn Trách Nhiệm Thiệt Hại (LDW)
                  </h1>
                  <p className="text-avis mt-2">
                    Miễn Trách Nhiệm Thiệt Hại ("<b>LDW</b>") không phải là bảo
                    hiểm và không bắt buộc. Nếu bạn chấp nhận đầy đủ LDW bằng
                    cách ký tắt vào tài liệu thuê xe với mức giá hàng ngày bổ
                    sung, cho mỗi ngày đầy đủ hoặc một phần mà xe được thuê cho
                    bạn và xe được vận hành theo đúng Thỏa Thuận, chúng tôi sẽ
                    chịu trách nhiệm cho việc mất mát hoặc thiệt hại đối với xe,
                    ngoại trừ trường hợp được phép bởi luật pháp, như mất, hỏng
                    hoặc chìa khóa hoặc thiết bị vào xe bị đánh cắp, dịch vụ kéo
                    xe hoặc thay lốp xe, trừ khi có liên quan đến tai nạn, hoặc
                    khôi phục xe nếu bị đánh cắp (trừ tại bang Alaska) và ngoại
                    trừ số tiền "trách nhiệm" của bạn, nếu có, được chỉ định
                    trong tài liệu thuê xe. Miễn Trách Nhiệm Thiệt Hại Một Phần
                    ("<b>PDW</b>") chỉ có sẵn khi luật pháp cho phép. Nếu bạn
                    chấp nhận PDW với mức giá hàng ngày được chỉ định, và xe
                    được vận hành theo đúng Thỏa Thuận, chúng tôi sẽ chịu trách
                    nhiệm cho mọi mất mát hoặc thiệt hại đối với xe lên đến số
                    tiền được chỉ định trên tài liệu thuê xe, và bạn chấp nhận
                    trách nhiệm cho tất cả các mất mát hoặc thiệt hại khác. Nếu
                    bạn không chấp nhận LDW hoặc PDW, bạn sẽ phải chịu trách
                    nhiệm cho mọi mất mát hoặc thiệt hại đối với xe. Mất mát và
                    thiệt hại được mô tả trong tài liệu thuê xe. Bạn xác nhận
                    rằng đã được thông báo rằng bảo hiểm của bạn có thể bảo hiểm
                    cho mất mát hoặc thiệt hại đối với xe. Bạn cũng xác nhận đã
                    đọc thông báo về mất mát thiệt hại được hiển thị trên tài
                    liệu thuê xe, hoặc trong các Điều Khoản và Điều Kiện này,
                    hoặc trong một biểu mẫu thông báo riêng.
                  </p>
                  <p className="text-avis mt-2">
                    Tại Canada, nếu bạn chấp nhận LDW, có thể vẫn có một khoản
                    không được miễn mà bạn sẽ phải chịu trách nhiệm trong trường
                    hợp mất mát hoặc thiệt hại đối với xe, khoản này sẽ được chỉ
                    định trên tài liệu thuê xe tại thời điểm thuê. Hiện tại,
                    khoản này tối đa là CA $500.
                  </p>
                  <p className="text-avis mt-2">
                    <b>Thông Báo Về Miễn Trách Nhiệm Thiệt Hại (LDW)</b>
                  </p>
                  <p className="text-avis mt-2">
                    Phần sau đây đáp ứng một số yêu cầu nhất định của bang về
                    việc tiết lộ thông tin.
                  </p>
                  <p className="text-avis mt-2">
                    <b>Connecticut:</b> Để xác định chi phí hàng năm cho Miễn
                    Trách Nhiệm Thiệt Hại (LDW hoặc PDW), nhân mức giá hàng ngày
                    trên trang 1 của tài liệu thuê xe với 365 ngày. Miễn Trách
                    Nhiệm Thiệt Hại bao gồm mất mát hoặc thiệt hại do trộm cắp,
                    va chạm, phá hoại, hoặc bất kỳ nguyên nhân nào khác. Cho dù
                    bạn có chấp nhận Miễn Trách Nhiệm Thiệt Hại hay không, hoặc
                    nếu Miễn Trách Nhiệm Thiệt Hại không được cho phép, bạn vẫn
                    phải chịu trách nhiệm cho mất mát hoặc thiệt hại đối với xe
                    do bạn hoặc người lái xe bổ sung được ủy quyền gây ra thông
                    qua hành vi cố ý hoặc thiếu sót; lái xe trong tình trạng say
                    rượu hoặc sử dụng ma túy; tham gia vào cuộc đua hoặc cuộc
                    thi; vận chuyển người hoặc tài sản để thuê; thực hiện hành
                    vi phạm tội hoặc hành vi có thể bị coi là phạm tội; không
                    hoàn thành báo cáo tai nạn hoặc trộm cắp; sử dụng hoặc vận
                    hành xe trái phép; vi phạm các điều khoản của Thỏa Thuận;
                    lái xe ngoài đường trải nhựa, sử dụng để đẩy hoặc kéo thứ gì
                    đó; hoặc có được xe thông qua gian lận hoặc sai lầm.
                  </p>
                  <p className="text-avis mt-2">
                    <b>
                      California, Iowa, Indiana, Louisiana, Hawaii,
                      Massachusetts, Minnesota, New York, Nevada, Rhode Island
                      và Texas, Bảo Vệ Người Tiêu Dùng:
                    </b>{" "}
                    Theo luật của các bang này, chính sách bảo hiểm ô tô cá nhân
                    PHẢI bao gồm việc thuê xe này để chống lại thiệt hại đối với
                    xe và chống lại mất mát quyền sử dụng xe. Do đó, việc mua
                    bất kỳ miễn trách nhiệm thiệt hại nào hoặc bảo hiểm cho rủi
                    ro mất mát hoặc thiệt hại đối với xe được bao phủ bởi Thỏa
                    Thuận là KHÔNG cần thiết nếu chính sách của bạn được cấp ở
                    một trong các bang này.
                  </p>
                  <p className="text-avis mt-2">
                    <b>Wisconsin:</b>
                  </p>
                  <p className="text-avis mt-2">
                    THÔNG BÁO VỀ TRÁCH NHIỆM ĐỐI VỚI THIỆT HẠI CHO XE THUÊ
                  </p>
                  <p className="text-avis mt-2">
                    Bang Wisconsin yêu cầu chúng tôi cung cấp thông tin sau về
                    trách nhiệm của bạn đối với thiệt hại đối với xe thuê và
                    việc mua miễn trách nhiệm thiệt hại. Trên các hợp đồng thuê
                    xe ở Wisconsin, tất cả các điều khoản của Thỏa Thuận đều có
                    hiệu lực, trừ khi có mâu thuẫn giữa các điều khoản cụ thể
                    của Wisconsin và các điều khoản khác của Thỏa Thuận, trong
                    trường hợp này, các điều khoản cụ thể của Wisconsin sẽ được
                    áp dụng.
                  </p>
                  <p className="text-avis mt-2">
                    THÔNG BÁO VỀ QUYỀN KIỂM TRA THIỆT HẠI
                  </p>
                  <p className="text-avis mt-2">
                    Nếu xe bị hư hại, chúng tôi không được thu bất kỳ khoản tiền
                    nào cho thiệt hại trừ khi bạn hoặc người lái xe được ủy
                    quyền mà chúng tôi yêu cầu chịu trách nhiệm đã được thông
                    báo kịp thời về quyền của bạn và bảo hiểm của bạn để kiểm
                    tra xe chưa được sửa chữa trong vòng hai ngày làm việc sau
                    khi chúng tôi được thông báo về thiệt hại.
                  </p>
                  <p className="text-avis mt-2">KHIẾU NẠI</p>
                  <p className="text-avis mt-2">
                    Nếu bạn có bất kỳ khiếu nại nào về việc chúng tôi yêu cầu
                    bạn chịu trách nhiệm về thiệt hại hoặc muốn có bản sao của
                    luật tiểu bang xác định đầy đủ quyền và nghĩa vụ của bạn,
                    hãy liên hệ:
                  </p>
                  <p className="text-avis mt-2">
                    Wisconsin Consumer Protection Bureau, P.O. Box 8911,
                    Madison, WI 53708-8911 608-224-4960 (khu vực Madison) hoặc
                    miễn phí 1-800-422-7128
                  </p>
                </div>
              ) : this.props.data === "Additional Liability Insurance (ALI)" ? (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    Bảo Hiểm Trách Nhiệm Bổ Sung (ALI)
                  </h1>
                  <p className="text-avis mt-2">
                    Nếu bạn chọn mua ALI, bảo hiểm sẽ được cung cấp cho bạn và
                    bất kỳ người lái xe nào được ủy quyền theo một chính sách
                    bảo hiểm ô tô bổ sung do Avis cấp. ALI cung cấp bảo vệ cho
                    các yêu cầu bồi thường ô tô bên thứ ba đối với phần chênh
                    lệch giữa giới hạn trách nhiệm tài chính tối thiểu được cung
                    cấp theo đoạn 22 ở trên và mức giới hạn trách nhiệm tổng hợp
                    tối đa $1.000.000 hoặc $2.000.000, tùy thuộc vào khu vực
                    thuê xe cho mỗi tai nạn gây thương tích, tử vong hoặc thiệt
                    hại tài sản. Bảo hiểm này được cung cấp theo một chính sách
                    bảo hiểm trách nhiệm bổ sung được mô tả đầy đủ trong tài
                    liệu và chịu sự điều chỉnh của tất cả các điều kiện và giới
                    hạn được mô tả trong đoạn 22 ở trên, ngoại trừ trong trường
                    hợp có bất kỳ điều gì khác trong Thỏa Thuận, các điều khoản
                    của chính sách sẽ luôn điều khiển. ALI không áp dụng cho
                    trách nhiệm đối với thương tích cơ thể hoặc thiệt hại tài
                    sản phát sinh từ bất kỳ "sử dụng bị cấm của xe" nào như được
                    mô tả trong đoạn 15 của Thỏa Thuận. Các ngoại lệ khác đối
                    với ALI được liệt kê trong chính sách ALI. Bạn hiểu rằng bạn
                    sẽ bị tính phí theo ngày cho toàn bộ ngày ngay cả khi bạn
                    không sử dụng xe cả ngày. Các tiết lộ liên quan đến Bảo Hiểm
                    Trách Nhiệm Bổ Sung tại bang Florida.
                  </p>
                  <p className="text-avis mt-2">
                    <b>Bảo Hiểm Trách Nhiệm Bổ Sung (ALI) là gì?</b>
                  </p>
                  <p className="text-avis mt-2">
                    <b>Avis</b> có cung cấp Bảo Hiểm Trách Nhiệm Bổ Sung (ALI)
                    tại tất cả các địa điểm ở Florida. ALI là một dịch vụ tùy
                    chọn đặc biệt do Avis cung cấp khi bạn thuê xe từ Avis. Đây
                    là một "Chính Sách Bảo Hiểm Trách Nhiệm Ô Tô Bổ Sung" cung
                    cấp bảo hiểm trách nhiệm bổ sung, trong giới hạn nhất định,
                    trên mức giới hạn được cung cấp trong Thỏa Thuận. ALI bảo
                    hiểm cho bạn và các nhà vận hành được ủy quyền theo Thỏa
                    Thuận chống lại các yêu cầu bồi thường của bên thứ ba đối
                    với bạn, khách hàng, vì thương tích/tử vong và thiệt hại tài
                    sản do sử dụng hoặc vận hành xe thuê của Avis theo đúng Thỏa
                    Thuận. ALI là một chính sách bảo hiểm riêng được cấp bởi
                    Công Ty Bảo Hiểm ACE American.
                  </p>
                  <p className="text-avis mt-2">
                    Nếu bạn chọn chấp nhận ALI, bạn sẽ phải trả một khoản phí
                    hàng ngày bổ sung như được chỉ ra trong Thỏa Thuận.{" "}
                    <b>Việc mua ALI không bắt buộc để thuê xe từ Avis.</b>
                  </p>
                  <p className="text-avis mt-2">
                    <b>Giới hạn bảo hiểm của ALI là gì?</b>
                  </p>
                  <p className="text-avis mt-2">
                    Giới hạn bảo hiểm của ALI bằng với sự khác biệt giữa mức bảo
                    hiểm ALI tối đa $1.000.000 tổng hợp (hoặc $2.000.000 tổng
                    hợp trong các khu vực sau: California, Florida, Hawaii và
                    New York) và giới hạn bảo vệ trách nhiệm được cung cấp theo
                    thỏa thuận thuê xe của Avis.{" "}
                    <b>
                      Lưu ý, tại tất cả các bang, chúng tôi chỉ cung cấp giới
                      hạn tổng hợp $1.000.000 trên các xe chở 10,12, và 15 hành
                      khách.
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    <b>Khi nào và ở đâu thì bảo hiểm ALI áp dụng?</b>
                  </p>
                  <p className="text-avis mt-2">
                    Bạn và các nhà vận hành được ủy quyền được bảo hiểm trong
                    khi lái xe thuê trong Hoa Kỳ và Canada, nhưng chỉ khi xe
                    được thuê và trả lại ở Hoa Kỳ. Bảo hiểm không áp dụng tại
                    Mexico.
                  </p>
                  <p className="text-avis mt-2">
                    <b>Làm thế nào để báo cáo một yêu cầu bồi thường?</b>
                  </p>
                  <p className="text-avis mt-2">
                    Nếu bạn bị tai nạn, bạn phải hoàn thành một báo cáo tai nạn
                    và gửi nó đến địa điểm thuê xe của Avis. Để thực hiện một
                    yêu cầu bồi thường hoặc thông báo về yêu cầu bồi thường, hãy
                    gửi thông báo bằng văn bản đến:
                  </p>
                  <p className="text-avis mt-2">Avis Rent A Car System, Inc.</p>
                  <p className="text-avis mt-2">Attn: Claims</p>
                  <p className="text-avis mt-2">P.O. Box 61247</p>
                  <p className="text-avis mt-2">Virginia Beach, VA 23466</p>
                  <p className="text-avis mt-2">1-866-446-8376</p>

                  <p className="text-avis mt-2">
                    <b>
                      Bảo hiểm ALI ảnh hưởng thế nào đến bảo hiểm ô tô hoặc
                      chính sách umbrella của bạn?
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    Chính sách bảo hiểm cá nhân của bạn trên xe ô tô sở hữu,
                    hoặc chính sách cá nhân khác, có thể cung cấp bảo hiểm bổ
                    sung, và ở mức độ đó, ALI có thể cung cấp sự trùng lặp về
                    bảo hiểm. Dù chính sách của bạn áp dụng ở điểm nào và ở mức
                    độ nào có thể chỉ được xác định bằng cách kiểm tra các điều
                    khoản của chính sách đó, vì các điều khoản này thường khác
                    nhau. Tuy nhiên, sự bảo vệ được cung cấp bởi ALI (nếu ALI
                    được chấp nhận), giống như các giới hạn bảo vệ của thỏa
                    thuận thuê xe của Avis, là chính trước các chính sách của
                    bạn. Điều này có nghĩa là trước khi chính sách của bạn có
                    thể áp dụng để trả yêu cầu bồi thường, sự bảo vệ $1.000.000
                    (hoặc $2.000.000 trong các khu vực: California, Florida,
                    Hawaii và New York) do sự kết hợp giữa ALI và các giới hạn
                    của thỏa thuận thuê xe của Avis sẽ phải được sử dụng hết.{" "}
                    <b>
                      Lưu ý, tại tất cả các bang, chúng tôi chỉ cung cấp giới
                      hạn tổng hợp $1.000.000 trên các xe chở 10,12, và 15 hành
                      khách.
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    <b>Các loại trừ nào áp dụng cho ALI?</b>
                  </p>
                  <p className="text-avis mt-2">
                    Dưới đây là một số điểm nổi bật về các ngoại lệ có thể khiến
                    ALI không áp dụng. Điều quan trọng là bạn đọc kỹ tài liệu
                    thuê xe và chính sách để biết tất cả các loại trừ.
                  </p>
                  <ul className="text-avis mt-2">
                    <li>
                      • Bất kỳ Sử Dụng Bị Cấm nào của Xe như được mô tả trong
                      đoạn 15 của Thỏa Thuận
                    </li>
                    <li>
                      • Thương tích cơ thể hoặc thiệt hại tài sản đối với bất kỳ
                      Người Được Bảo Hiểm nào; hoặc, trong phạm vi được phép bởi
                      luật của bang nơi tài liệu thuê xe được ký vào thời điểm
                      thuê/nơi xe được thuê, thương tích cơ thể hoặc thiệt hại
                      tài sản đối với bất kỳ người nào có quan hệ huyết thống,
                      hôn nhân hoặc nhận con nuôi với bất kỳ Người Được Bảo Hiểm
                      nào và đang sống trong cùng hộ gia đình.
                    </li>
                    <li>
                      • Bảo hiểm "Người Lái Xe Không Bảo Hiểm"/"Người Lái Xe
                      Thiếu Bảo Hiểm" không được cung cấp trong chính sách ngoại
                      trừ ở các bang nơi được luật pháp yêu cầu với số tiền tối
                      đa là $100.000 hoặc số tiền theo quy định của pháp luật
                    </li>
                    <li>
                      • Bảo hiểm "Không Lỗi" và các bảo hiểm bổ sung hoặc tùy
                      chọn khác không được cung cấp bởi chính sách
                    </li>
                    <li>
                      • Thiệt hại mang tính trừng phạt hoặc mang tính hình phạt
                      trong phạm vi cho phép của pháp luật
                    </li>
                  </ul>
                  <p className="text-avis mt-2">
                    <b>
                      Có bất kỳ hạn chế đặc biệt nào đối với việc mua ALI không?
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    Tại Florida, ALI không thể được mua nếu thời hạn của tài
                    liệu thuê xe kéo dài hơn 30 ngày, bảo hiểm không thể được
                    cung cấp trong hơn 30 ngày liên tiếp; và nếu tài liệu thuê
                    xe được gia hạn vượt quá 30 ngày, bảo hiểm có thể được gia
                    hạn một lần, không quá 30 ngày.
                  </p>
                  <p className="text-avis mt-2">
                    Đây chỉ là một bản tóm tắt về ALI. Các điều khoản, điều kiện
                    và loại trừ cụ thể của nó phải tuân thủ tất cả các điều
                    khoản, giới hạn và loại trừ trong tài liệu thuê xe và chính
                    sách ALI được cấp bởi Công Ty Bảo Hiểm ACE American, một
                    trong những công ty con có trụ sở tại Hoa Kỳ của ACE Limited
                    (NYSE: ACE). "ACE" và logo của ACE là các dấu hiệu dịch vụ
                    của Tập Đoàn ACE, bao gồm ACE Limited và các công ty con của
                    nó.
                  </p>
                  <p className="text-avis mt-2">
                    Bản tóm tắt này không nhằm cung cấp mô tả đầy đủ về các điều
                    khoản, điều kiện và loại trừ của chính sách. Để biết thêm
                    chi tiết, chúng tôi mời bạn xem xét bản sao của chính sách,
                    có sẵn để bạn kiểm tra, theo yêu cầu, tại địa điểm Avis mà
                    bạn thuê xe.
                  </p>
                  <p className="text-avis mt-2">
                    Nhân viên Avis, đại lý hoặc người đại diện không đủ điều
                    kiện để đánh giá mức độ bảo hiểm hiện có của người thuê.
                  </p>
                </div>
              ) : this.props.data === "Personal Accident Insurance (PAI)" ? (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    Bảo Hiểm Tai Nạn Cá Nhân (PAI)
                  </h1>
                  <p className="text-avis mt-2">
                    Bạn sẽ phải trả tiền cho Bảo Hiểm Tai Nạn Cá Nhân nếu bạn
                    chấp nhận nó. Bạn hiểu rằng bạn sẽ bị tính phí hàng ngày cho
                    một ngày đầy đủ ngay cả khi bạn không sử dụng xe trong suốt
                    ngày đó.
                  </p>
                  <p className="text-avis mt-2">
                    <b>
                      <u>PAI – Bảo Hiểm Tai Nạn Cá Nhân (nếu có)</u>
                    </b>
                  </p>
                  <p className="text-avis mt-2">
                    PAI cung cấp các lợi ích về y tế, bệnh viện, tử vong và mất
                    một phần cơ thể do chấn thương tai nạn. Bảo hiểm này có giá
                    trị trong suốt thời gian thuê xe và không quá 30 ngày liên
                    tiếp.
                  </p>
                  <p className="text-avis mt-2">
                    PAI cung cấp các lợi ích tối đa sau đây cho chấn thương do
                    tai nạn:
                  </p>
                  <ul className="text-avis mt-2">
                    <li>
                      • Tử vong do tai nạn và mất một phần cơ thể lên đến
                      $175,000* (người thuê) và $25,000** (hành khách).
                    </li>
                    <li>• Chi phí y tế tai nạn lên đến $10,000</li>
                    <li>
                      • Trợ cấp tai nạn bệnh viện*** $500 mỗi ngày lên đến 30
                      ngày
                    </li>
                  </ul>
                  <p className="text-avis mt-2">*$200,000 ở NY; $50,000 ở NH</p>
                  <p className="text-avis mt-2">
                    **$200,000 ở NY; $175,000 ở CT, IN, KS, MO, MT, VT, VA, WA;
                    $50,000 ở NH
                  </p>
                  <p className="text-avis mt-2">
                    ***Trợ cấp Tai Nạn Bệnh Viện không có sẵn ở NY
                  </p>
                  <p className="text-avis mt-2">
                    <b>PAI Plus</b>
                  </p>
                  <p className="text-avis mt-2">
                    PAI Plus được thiết kế để đáp ứng nhu cầu của những người
                    thuê xe yêu cầu mức bảo hiểm mở rộng (ví dụ: du khách từ các
                    quốc gia không phải Hoa Kỳ).
                  </p>
                  <p className="text-avis mt-2">
                    Người thuê được bảo hiểm 24 giờ một ngày trong suốt thời
                    gian của hợp đồng thuê lên đến 30 ngày.
                  </p>
                  <ul className="text-avis mt-2">
                    <li>
                      • $250,000* tử vong do tai nạn và mất một phần cơ thể
                    </li>
                    <li>• $15,000 bảo hiểm y tế cho chấn thương do tai nạn</li>
                    <li>
                      • $500*** mỗi ngày lên đến 30 ngày cho việc nhập viện do
                      tai nạn
                    </li>
                  </ul>
                  <p className="text-avis mt-2">
                    Trong các phương tiện có sức chứa lên đến 15 người, hành
                    khách sẽ được bảo hiểm khi ở trong xe cho:
                  </p>
                  <ul className="text-avis mt-2">
                    <li>
                      • $125,000** mỗi hành khách cho tử vong do tai nạn và mất
                      một phần cơ thể
                    </li>
                    <li>• $15,000 bảo hiểm y tế cho chấn thương do tai nạn</li>
                    <li>
                      • $500*** mỗi ngày lên đến 30 ngày cho việc nhập viện do
                      tai nạn
                    </li>
                    <li>• $250,000 ở NY; $50,000 ở NH</li>
                    <li>
                      • **$250,000 ở NY; $250,000 ở CT, IN, KS, MO, MT, VT, VA,
                      WA; $50,000 ở NH
                    </li>
                    <li>• ***Trợ cấp Tai Nạn Bệnh Viện không có sẵn ở NY</li>
                  </ul>
                  <p className="text-avis mt-2">
                    Để xem toàn bộ tài liệu giới thiệu, vui lòng{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-us-protectionscoverages-brochure-062922.pdf"
                      target="_blank"
                    >
                      nhấn vào đây.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    Để xem giấy chứng nhận chính sách cho các bang chung, vui
                    lòng{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-pai-cert-general-states.pdf"
                      target="_blank"
                    >
                      nhấn vào đây.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    Để xem giấy chứng nhận chính sách cho New York, vui lòng{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-pai-cert-ny-feb23.pdf"
                      target="_blank"
                    >
                      nhấn vào đây.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    Để xem giấy chứng nhận chính sách cho Connecticut, Indiana,
                    Kansas, Missouri, Montana, Vermont, Virginia, và Washington,
                    vui lòng{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-pai-cert-CT-IN-KS-MO-MT-VT-VA-WA-feb23.pdf"
                      target="_blank"
                    >
                      nhấn vào đây.
                    </a>
                  </p>
                </div>
              ) : this.props.data === "Personal Effect Insurance (PEP)" ? (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    Bảo Hiểm Đồ Dùng Cá Nhân (PEP)
                  </h1>
                  <p className="text-avis mt-2">
                    ESP, nếu có, chỉ có sẵn cho những người thuê xe không cư trú
                    tại Mỹ. Bạn sẽ phải trả tiền cho ESP nếu bạn chấp nhận nó.
                    Bạn sẽ bị tính phí hàng ngày cho một ngày đầy đủ ngay cả khi
                    bạn không sử dụng xe trong suốt ngày đó. ESP cung cấp bảo
                    hiểm cho du khách trong trường hợp ốm đau trong thời gian
                    thuê xe và cũng bảo vệ những người đồng hành của người thuê
                    xe không cư trú tại Mỹ.
                  </p>
                  <p className="text-avis mt-2">
                    Để xem toàn bộ tài liệu giới thiệu, vui lòng{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-us-protectionscoverages-brochure-062922.pdf"
                      target="_blank"
                    >
                      nhấn vào đây.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    Để xem giấy chứng nhận chính sách cho các bang chung, vui
                    lòng{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-esp-certificate-general-states.pdf"
                      target="_blank"
                    >
                      nhấn vào đây.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    Để xem giấy chứng nhận chính sách cho New York, vui lòng{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-esp-cert-ny-feb23.pdf"
                      target="_blank"
                    >
                      nhấn vào đây.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    Để xem giấy chứng nhận chính sách cho Connecticut, Indiana,
                    Kansas, Missouri, Montana, Vermont, Virginia, và Washington,
                    vui lòng{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-esp-cert-CT-IN-KS-MO-MT-VT-VA-WA-feb23.pdf"
                      target="_blank"
                    >
                      nhấn vào đây.
                    </a>
                  </p>
                </div>
              ) : (
                <div className="col-sm-12">
                  <h1 className="link-avis text-up fw-normal mb-5">
                    Kế hoạch phòng ngừa bệnh tật khẩn cấp (ESP)
                  </h1>
                  <p className="text-avis mt-2">
                    ESP, nếu có, chỉ dành cho những người thuê xe không cư trú
                    tại Mỹ. Bạn sẽ phải trả tiền cho ESP nếu bạn chấp nhận nó.
                    Bạn sẽ bị tính phí hàng ngày cho một ngày đầy đủ ngay cả khi
                    bạn không sử dụng xe trong suốt ngày đó. ESP cung cấp bảo
                    hiểm cho du khách trong trường hợp ốm đau trong thời gian
                    thuê xe và cũng bảo vệ những người đồng hành của người thuê
                    xe không cư trú tại Mỹ.
                  </p>
                  <p className="text-avis mt-2">
                    Để xem toàn bộ tài liệu giới thiệu, vui lòng{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-us-protectionscoverages-brochure-062922.pdf"
                      target="_blank"
                    >
                      nhấn vào đây.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    Để xem giấy chứng nhận chính sách cho các bang chung, vui
                    lòng{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-esp-certificate-general-states.pdf"
                      target="_blank"
                    >
                      nhấn vào đây.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    Để xem giấy chứng nhận chính sách cho New York, vui lòng{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-esp-cert-ny-feb23.pdf"
                      target="_blank"
                    >
                      nhấn vào đây.
                    </a>
                  </p>
                  <p className="text-avis mt-2">
                    Để xem giấy chứng nhận chính sách cho Connecticut, Indiana,
                    Kansas, Missouri, Montana, Vermont, Virginia, và Washington,
                    vui lòng{" "}
                    <a
                      className="link-avis"
                      href="https://www.avis.com/content/dam/avis/na/us/common/pdf-files/avis-esp-cert-CT-IN-KS-MO-MT-VT-VA-WA-feb23.pdf"
                      target="_blank"
                    >
                      nhấn vào đây.
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}
