import React from "react";

const AD = () => {
  return (
    <>
      <section className="border-top border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb text-avis py-3">
                  <li className="breadcrumb-item">Ô tô & Dịch vụ</li>
                  <li className="breadcrumb-item">Sản phẩm & Dịch vụ</li>
                  <li className="breadcrumb-item link-avis" aria-current="page">
                    Người Lái Xe Bổ Sung
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-2">
          <div className="row py-3">
            <div className="col-12">
              <p className="text-avis mt-2">Chia sẻ quyền lái xe</p>
              <p className="text-avis mt-2">
                Chỉ có tài xế chính được bảo hiểm để lái một trong những xe thuê
                của chúng tôi, nhưng bất kỳ số lượng tài xế bổ sung nào cũng có
                thể được thêm vào đặt chỗ của bạn.
              </p>
              <p className="text-avis mt-2">
                Các tài xế bổ sung phải tuân thủ tất cả các điều khoản và điều
                kiện giống như tài xế chính và được bảo hiểm theo cùng một hợp
                đồng thuê xe.
              </p>
              <p className="text-avis mt-2">
                Điều quan trọng nhất cần nhớ: Mỗi tài xế phải mang theo bằng lái
                xe, Giấy phép Lái xe Quốc tế và các giấy tờ liên quan khi đến
                lấy xe thuê.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AD;
