import React from "react";
import Card from "../../common/card/Card";

const ContentFraudScam = () => {
  return (
    <div className="carsContainer">
      <div className="container py-5 px-4">
        <div className="row">
          <Card
            title="Câu hỏi thường gặp"
            description="Tại đây bạn sẽ tìm thấy câu trả lời cho các câu hỏi phổ biến về vận chuyển, thanh toán, trả hàng và nhiều hơn nữa. Khám phá thông tin bạn cần để có trải nghiệm mua sắm thuận lợi trên trang web của chúng tôi."
            link="https://faq.avis.co.uk/"
          />
          <Card
            title="Điều khoản và điều kiện"
            description="Đọc các điều khoản và điều kiện của chúng tôi để hiểu quyền và nghĩa vụ của bạn khi sử dụng trang web của chúng tôi. Khám phá thông tin về thanh toán, vận chuyển, trả hàng, chính sách bảo mật và các điều khoản sử dụng trang web. Bằng cách đọc các điều khoản và điều kiện của chúng tôi, bạn đồng ý tuân thủ tất cả các quy tắc được thiết lập cho việc sử dụng trang web của chúng tôi."
            link="https://www.avis.co.uk/TermsAndCondition"
          />
        </div>
      </div>
    </div>
  );
};

export default ContentFraudScam;
