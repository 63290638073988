import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL, BRAND } from "../../core/constant.js";
import feesCountry from "../../avis_cancel_fees.json";
import { format } from "date-fns";

const EX = (props) => {
  const navigate = useNavigate();

  const [rateCode, setRateCode] = useState("");
  const [adminTax, setAdminTax] = useState(0);
  const [currencyFrom, setCurrencyFrom] = useState("");
  const [taxValue, setTaxValue] = useState(0);
  const [insurance, setInsurance] = useState([]);
  const [price, setPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalIdr, setTotalIdr] = useState(0);
  const [taxIdr, setTaxIdr] = useState(0);
  const [baseIdr, setBaseIdr] = useState(0);
  const [fees, setFees] = useState("");
  const bufferRate = 0.05;
  // const priceWithBuffer = price * (1 + bufferRate);
  const [priceWithBuffer, setPriceWithBuffer] = useState(0);
  const totalWithBuffer = priceWithBuffer + tax;
  const priceIdrWithBuffer = Math.round(baseIdr * (1 + bufferRate));
  const totalIdrWithBuffer = Math.round(priceIdrWithBuffer + taxIdr);

  const {
    transactionId,
    pickupNameLocation,
    dropoffNameLocation,
    pickupCityLocation,
    dropoffCityLocation,
    pickupCodeLocation,
    dropoffCodeLocation,
    pickupCountryCode,
    pickupAddress1Location,
    dropoffAddress1Location,
    pickupAddress2Location,
    dropoffAddress2Location,
    pickupPostalCodeLocation,
    dropoffPostalCodeLocation,
    pickupTelephoneLocation,
    dropoffTelephoneLocation,
    pickupDate,
    dropoffDate,
    vehicle,
    couponCode,
  } = props.before;

  const myLink = {
    color: "rgb(212, 0, 42)",
  };
  const myBorder = {
    borderBottom: "2px solid rgb(212, 0, 42)",
  };
  const myLinkDis = {
    color: "rgb(168, 168, 168)",
  };
  const myBorderDis = {
    borderBottom: "2px solid rgb(168, 168, 168)",
  };
  const btnLink = {
    backgroundColor: "rgb(212, 0, 42)",
    color: "rgb(255, 255, 255)",
    padding: "0px 50px",
  };

  const handleRateCode = () => {
    if (pickupCodeLocation === "US") {
      setRateCode("XX");
    } else if (pickupCodeLocation === "CA") {
      setRateCode("S6I");
    } else {
      if (props.before.rateCode === "") {
        setRateCode(props.before.vehicle?.rate_totals?.rate?.rate_code);
      } else {
        setRateCode(props.before.rateCode);
      }
    }
  };

  const calculateTotalCost = async (
    productType,
    pickupDate,
    dropoffDate,
    baseCost
  ) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/admin/buffer/calculate`,
        {
          params: {
            productType: productType,
            pickup_date: pickupDate,
            dropoff_date: dropoffDate,
            base_cost: baseCost,
          },
        }
      );

      if (response.data.success) {
        return response.data.data.total_cost_with_buffer;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Failed to calculate total cost:", error);
      return null;
    }
  };

  useEffect(() => {
    if (pickupCountryCode === "US") {
      setRateCode("XX");
    } else if (pickupCountryCode === "CA") {
      setRateCode("S6I");
    } else {
      if (props.before.rateCode === "") {
        setRateCode(props.before.vehicle?.rate_totals?.rate?.rate_code);
      } else {
        setRateCode(props.before.rateCode);
      }
    }

    const bebe = localStorage.getItem("bebe");
    const getRates = async () => {
      try {
        const adminTaxRes = await axios.get(`${API_URL}/api/booking/tax`, {
          params: {
            productType: BRAND,
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });

        const ratesBody = {
          transaction: {
            transaction_id: transactionId,
          },
          product: {
            brand: BRAND,
          },
          reservation: {
            pickup_date: format(pickupDate, "yyyy-MM-dd'T'HH:mm:ss"),
            pickup_location: pickupCodeLocation,
            dropoff_date: format(dropoffDate, "yyyy-MM-dd'T'HH:mm:ss"),
            dropoff_location: dropoffCodeLocation,
            vehicle_sipp_code: vehicle.category.sipp_code,
          },
          rate_totals: {
            rate: {
              country_code: "ID",
              rate_code: rateCode,
              discount: {
                code: vehicle.rate_totals?.rate?.discount?.code,
              },
              membership: {
                code: "",
              },
              coupon: {
                code: "",
              },
            },
          },
        };

        const ratesRes = await axios.post(
          "https://stage.abgapiservices.com/cars/catalog/v2/vehicles/rates",
          ratesBody,
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + bebe,
              client_id: "04e19f27",
            },
          }
        );

        if (adminTaxRes.status === 200) {
          setAdminTax(adminTaxRes.data.tax.tax);
        }

        if (ratesRes.status === 200) {
          setCurrencyFrom(
            ratesRes.data.reservation.rate_totals.rate.currency ??
              vehicle.rate_totals.rate.currency
          );
          setTaxValue(
            ratesRes.data.reservation.rate_totals.totals.taxes_fees_total
          );
          setInsurance(ratesRes.data.reservation.insurance);
          setPrice(
            ratesRes.data.reservation.rate_totals.totals.vehicle_total ??
              vehicle.rate_totals.pay_later.vehicle_total
          );
          setTax(ratesRes.data.reservation.rate_totals.totals.taxes_fees_total);
          setTotal(
            ratesRes.data.reservation.rate_totals.totals.reservation_total
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (rateCode) {
      getRates();
    }
  }, [
    dropoffCodeLocation,
    dropoffDate,
    pickupCodeLocation,
    pickupDate,
    rateCode,
    transactionId,
    vehicle.category.sipp_code,
    vehicle.rate_totals.pay_later.vehicle_total,
    vehicle.rate_totals.rate.currency,
    vehicle.rate_totals.rate?.discount?.code,
  ]);

  useEffect(() => {
    const convertCurrency = async () => {
      if (!currencyFrom) return;

      try {
        const currentDate = new Date().toISOString().split("T")[0];

        const convertTotalToIdrRes = await axios.get(
          "https://api.fxratesapi.com/convert",
          {
            params: {
              from: currencyFrom,
              to: "IDR",
              date: currentDate,
              amount: total,
              format: "json",
            },
          }
        );

        const convertBaseToIdrRes = await axios.get(
          "https://api.fxratesapi.com/convert",
          {
            params: {
              from: currencyFrom,
              to: "IDR",
              date: currentDate,
              amount: price,
              format: "json",
            },
          }
        );

        const convertTaxToIdrRes = await axios.get(
          "https://api.fxratesapi.com/convert",
          {
            params: {
              from: currencyFrom,
              to: "IDR",
              date: currentDate,
              amount: tax,
              format: "json",
            },
          }
        );

        if (convertTotalToIdrRes.status === 200) {
          setTotalIdr(Math.round(convertTotalToIdrRes.data.result));
        }

        if (convertTaxToIdrRes.status === 200) {
          setTaxIdr(Math.round(convertTaxToIdrRes.data.result));
        }

        if (convertBaseToIdrRes.status === 200) {
          setBaseIdr(Math.round(convertBaseToIdrRes.data.result));
        }
      } catch (error) {
        console.error(error);
      }
    };

    convertCurrency();
  }, [currencyFrom, total, price, tax]);

  useEffect(() => {
    const fee = feesCountry.find(
      (val) => val["Country Code"] === pickupCodeLocation
    );

    if (fee === undefined) {
      setFees("IDR 300.000");
    } else {
      setFees("$" + fee["AVIS WEBSITE"]);
    }
  }, [pickupCodeLocation]);

  const calculateTaxIdr = Math.round(
    (totalIdrWithBuffer * (taxValue === 0 ? 0 : Number(adminTax))) / 100 +
      taxIdr
  );

  const calculateTotalIdr = Math.round(
    totalIdrWithBuffer +
      ((taxIdr + priceIdrWithBuffer) *
        (taxValue === 0 ? 0 : Number(adminTax))) /
        100
  );

  useEffect(() => {
    const calculateWithBuffer = async () => {
      if (price > 0) {
        // Pastikan price sudah terisi
        const bufferPrice = await calculateTotalCost(
          BRAND,
          pickupDate,
          dropoffDate,
          price
        );

        if (bufferPrice !== null) {
          setPriceWithBuffer(bufferPrice);
        } else {
          console.error("Failed to retrieve total cost with buffer.");
        }
      }
    };

    calculateWithBuffer();
  }, [price, pickupDate, dropoffDate]);

  const handlePay = (paymentType) => {
    navigate("/reviewandbook", {
      state: {
        paymentType: paymentType,
        transactionId: transactionId,
        pickupCodeLocation: pickupCodeLocation,
        dropoffCodeLocation: dropoffCodeLocation,
        pickupNameLocation: pickupNameLocation,
        dropoffNameLocation: dropoffNameLocation,
        pickupTelephoneLocation: pickupTelephoneLocation,
        dropoffTelephoneLocation: dropoffTelephoneLocation,
        pickupAddress1Location: pickupAddress1Location,
        dropoffAddress1Location: dropoffAddress1Location,
        pickupAddress2Location: pickupAddress2Location,
        dropoffAddress2Location: dropoffAddress2Location,
        pickupCityLocation: pickupCityLocation,
        dropoffCityLocation: dropoffCityLocation,
        pickupPostalCodeLocation: pickupPostalCodeLocation,
        dropoffPostalCodeLocation: dropoffPostalCodeLocation,
        pickupDate: pickupDate,
        dropoffDate: dropoffDate,
        pickupCoordinate: props.before.pickupCoordinate,
        dropoffCoordinate: props.before.dropoffCoordinate,
        couponCode: couponCode,
        vehicle: vehicle,
        total: totalWithBuffer,
        tax: tax,
        price: priceWithBuffer,
        baseIdr: priceIdrWithBuffer,
        totalIdr: calculateTotalIdr,
        taxIdr: calculateTaxIdr,
        image: props.before.image,
        name: props.before.name,
        model: props.before.model,
        make: props.before.make,
        taxValue: props.before.taxValue,
        rateCode: rateCode,
        currencyFrom: currencyFrom,
        insurance: insurance,
        fees: fees,
        age: props.before.age,
        adminTax: adminTax,
      },
    });
  };

  return (
    <div>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <p style={myLinkDis}>1</p>
              <p style={myBorderDis}></p>
            </div>
            <div className="col-sm-3">
              <p style={myLinkDis}>2</p>
              <p style={myBorderDis}></p>
            </div>
            <div className="col-sm-3">
              <p style={myLink}>3 Tùy chọn thuê</p>
              <p style={myBorder}></p>
            </div>
            <div className="col-sm-3">
              <p style={myLinkDis}>4</p>
              <p style={myBorderDis}></p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-6 border-bottom">
                  <div className="row text-black">
                    <div className="col-sm-6">
                      <p style={myLink}>Điểm đón</p>
                      <p>
                        <b>{pickupNameLocation}</b>
                        <br />
                        {pickupCityLocation},{pickupCodeLocation},
                        <br />
                        {pickupAddress1Location},{pickupAddress2Location},
                        {pickupPostalCodeLocation}
                        <br />
                        <b>{pickupTelephoneLocation}</b>
                        <br />
                        <b>Mã địa điểm: </b> {pickupCodeLocation}
                      </p>
                      <p>
                        <b>{format(pickupDate, "HH:mm a MMMM d, yyyy")}</b>
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <p style={myLink}>Trả xe</p>
                      <p>
                        <b>{dropoffNameLocation}</b>
                        <br />
                        {dropoffCityLocation},{dropoffCodeLocation}
                        <br />
                        {dropoffAddress1Location},{dropoffAddress2Location},
                        {dropoffPostalCodeLocation}
                        <br />
                        <b>{dropoffTelephoneLocation}</b>
                        <br />
                        <b>Mã địa điểm: </b> {dropoffCodeLocation}
                      </p>
                      <p>
                        <b>{format(dropoffDate, "HH:mm a MMMM d, yyyy")}</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 border-start border-bottom">
                  <div className="row text-black">
                    <div className="col-sm-6">
                      <p>Giá cơ bản</p>
                      <p style={myLink}>Thuế và phí</p>
                      <p>Tổng dự tính</p>
                      <p style={myLink}>Xem Điều kiện giá</p>
                    </div>
                    <div className="col-sm-6 text-end">
                      <p>
                        {currencyFrom} {priceWithBuffer.toFixed(2)}
                      </p>
                      <p>
                        {currencyFrom} {tax.toFixed(2)}
                      </p>
                      <p>
                        <b>
                          {currencyFrom} {totalWithBuffer.toFixed(2)}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="row mt-2 text-black">
                    <div className="col-sm-3">
                      <img
                        alt="carImage"
                        src={props?.before?.image}
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-sm-9">
                      <p>
                        <h5 className="text-dark">{props?.before?.name}</h5>
                      </p>
                      <p>
                        {props?.before?.make} {props?.before?.model}
                      </p>
                      <div className="col-sm-9">
                        <p>
                          <img src="/assets/images/icon/user.png" alt="user" />{" "}
                          {props?.before?.vehicle?.capacity.seats}
                          {props.before.vehicle.capacity &&
                            props.before.vehicle.capacity.luggage_capacity &&
                            props.before.vehicle.capacity.luggage_capacity
                              .large_suitcase && (
                              <>
                                <img
                                  alt="suitcase"
                                  src="/assets/images/icon/suitcase.png"
                                  className="ms-2"
                                />{" "}
                                {props?.before?.vehicle?.capacity
                                  ?.luggage_capacity.large_suitcase ?? 0}
                                L
                                <img
                                  alt="suitcase"
                                  src="/assets/images/icon/suitcase.png"
                                  className="ms-2"
                                />{" "}
                                {props?.before?.vehicle?.capacity
                                  ?.luggage_capacity.large_suitcase ?? 0}
                                S
                              </>
                            )}
                        </p>
                        <p>
                          {
                            props?.before?.vehicle?.category
                              ?.vehicle_transmission
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 border-start text-success">
                  <p className="text-end">Bao gồm: </p>
                  <ul className="text-end">
                    {insurance?.map((item, index) => (
                      <li key={index}>{item.name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row mt-4 py-4 border-top border-bottom">
            <div
              className="col-sm-4 align-self-center text-center mb-4"
              style={{ width: "100%" }}
            >
              <p className="text-dark">
                <b>QUAN TRỌNG</b>: Hủy trước 48 giờ so với thời gian dự kiến
                nhận xe, bạn sẽ nhận lại khoản tiền đã trả trước, trừ phí hủy là{" "}
                <b>{fees}</b>.
              </p>
            </div>
            <div className="col-sm-12 d-flex flex-md-row flex-column justify-content-between align-items-center">
              <div className="d-flex justify-content-between flex-column flex-md-row align-items-center">
                <ul>
                  <li className="me-5">
                    Giá cơ bản:{" "}
                    <strong>
                      {currencyFrom} {priceWithBuffer.toFixed(2)}
                    </strong>
                  </li>
                  <li className="me-5">
                    Thuế và phí:{" "}
                    <strong>
                      {currencyFrom} {tax.toFixed(2)}
                    </strong>
                  </li>
                  <li className="me-3">
                    Tổng dự tính:{" "}
                    <strong>
                      {currencyFrom} {totalWithBuffer.toFixed(2)}
                    </strong>
                  </li>
                </ul>
                <button
                  className="btn py-2 me-3"
                  style={btnLink}
                  disabled={total === 0}
                  onClick={() => {
                    handlePay("paylaters");
                  }}
                >
                  Trả sau
                </button>
              </div>
              <div className="d-flex justify-content-between flex-column flex-md-row align-items-center">
                <ul>
                  <li className="me-5">
                    Giá cơ bản:{" "}
                    <strong>
                      IDR {priceIdrWithBuffer.toLocaleString("id")}
                    </strong>
                  </li>
                  <li className="me-5">
                    Thuế và phí:{" "}
                    <strong>IDR {calculateTaxIdr.toLocaleString("id")}</strong>
                  </li>
                  <li className="me-5">
                    Tổng dự tính:{" "}
                    <strong>
                      IDR {calculateTotalIdr.toLocaleString("id")}
                    </strong>
                  </li>
                </ul>
                <button
                  className="btn py-2"
                  style={btnLink}
                  disabled={totalIdr === 0}
                  onClick={() => {
                    handlePay("paynows");
                  }}
                >
                  Trả ngay
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EX;
