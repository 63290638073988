import React from "react";

export default class UG extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/onewaycarrental1.webp)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "200px",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb text-avis py-3">
                    <li className="breadcrumb-item">Xe & Dịch Vụ</li>
                    <li className="breadcrumb-item">Sản Phẩm & Dịch Vụ</li>
                    <li className="breadcrumb-item">Mỹ & Canada</li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      GPS Navigation
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="banner-one" style={myStyle}>
          <div className="banner-one__inner py-4">
            <div className="container">
              <div className="banner-one__content text-center">
                <div className="title">
                  <h3 className="text-white text-up">
                    TIẾT KIỆM DỮ LIỆU CỦA BẠN - ĐẶT XE VỚI GPS
                  </h3>
                  <p className="text-white">
                    Điều hướng chuyến đi của bạn mà không cần sử dụng dữ liệu di
                    động.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-2">
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">CHI TIẾT</h3>
                <p className="text-avis mt-2">
                  Khi chuyến đi đưa bạn đến nơi mới, không có thời gian để chơi
                  trò tìm kiếm. Với GPS, bạn có thể tra cứu địa chỉ, khách sạn
                  và điểm tham quan - mà không cần sử dụng dữ liệu di động của
                  bạn.
                </p>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal link-avis">
                  <u>
                    <i>ĐIỀU KHOẢN VÀ ĐIỀU KIỆN</i>
                  </u>
                </h3>
                <p className="text-avis mt-2">
                  Điều khoản và điều kiện GPS Navigation:
                </p>
                <ul>
                  <li className="text-avis mt-2">
                    • Độ tin cậy của hướng dẫn phụ thuộc vào thời gian cập nhật
                    phần mềm và thông tin bản đồ đã được cung cấp bởi nhà sản
                    xuất bản đồ được cài đặt.
                  </li>
                  <li className="text-avis mt-2">
                    • Tôi (người thuê) xác nhận rằng xe thuê có thể được trang
                    bị GPS sử dụng vệ tinh định vị toàn cầu.
                  </li>
                  <li className="text-avis mt-2">
                    • Tôi xác nhận rằng GPS có thể bị giới hạn bởi các điều kiện
                    khí quyển hoặc địa hình.
                  </li>
                  <li className="text-avis mt-2">
                    • Tôi cũng xác nhận rằng GPS có thể không hoạt động nếu GPS
                    bị can thiệp hoặc bị hỏng trong tai nạn hoặc nếu hệ thống vệ
                    tinh bị cản trở và không hoạt động.
                  </li>
                  <li className="text-avis mt-2">
                    • Khách hàng chịu trách nhiệm cho tất cả các chi phí trong
                    trường hợp mất, bị đánh cắp hoặc hỏng đơn vị GPS và tất cả
                    các thành phần của nó.
                  </li>
                  <li className="text-avis mt-2">
                    • Người thuê đồng ý giải phóng và giữ Avis vô tội trước bất
                    kỳ sự cố nào của GPS.
                  </li>
                  <li className="text-avis mt-2">
                    • GPS có thể được yêu cầu tại quầy cho thuê.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
