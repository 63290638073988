import React from "react";

export default class FP extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/onewaycarrental1.webp)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "200px",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb text-avis py-3">
                    <li className="breadcrumb-item">Ô tô & Dịch vụ</li>
                    <li className="breadcrumb-item">Sản phẩm & Dịch vụ</li>
                    <li className="breadcrumb-item">Hoa Kỳ & Canada</li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      Kế hoạch Nhiên liệu
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="banner-one" style={myStyle}>
          <div className="banner-one__inner py-4">
            <div className="container">
              <div className="banner-one__content text-center">
                <div className="title">
                  <h3 className="text-white text-up">
                    KẾ HOẠCH NHIÊN LIỆU VÀ TÙY CHỌN DỊCH VỤ NHIÊN LIỆU
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-2">
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">
                  CHI TIẾT KẾ HOẠCH NHIÊN LIỆU
                </h3>
                <p className="text-avis mt-2">
                  <b>Một điểm dừng ít hơn trên đường về nhà của bạn.</b>
                </p>
                <ul>
                  <li className="text-avis mt-2">
                    • Tùy chọn nhiên liệu phù hợp với nhu cầu của bạn
                  </li>
                  <li className="text-avis mt-2">• Trả trước</li>
                  <li className="text-avis mt-2">
                    • Bỏ qua việc đổ xăng và tiết kiệm thời gian trong chuyến đi
                    của bạn
                  </li>
                </ul>
                <p className="text-avis mt-2">
                  Khi đến lúc đổ đầy bình, hãy chọn cách phù hợp nhất với lịch
                  trình và ngân sách của bạn:
                </p>
                <ul>
                  <li className="text-avis mt-2">
                    • Tùy chọn Dịch vụ Nhiên liệu
                  </li>
                  <li className="text-avis mt-2">• Tự phục vụ*</li>
                  <li className="text-avis mt-2">
                    • EZFuel® và Phí Dịch vụ Nhiên liệu*
                  </li>
                </ul>
                <p className="text-avis mt-2">
                  * Có thể yêu cầu tại quầy cho thuê
                </p>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">
                  Tùy chọn Dịch vụ Nhiên liệu
                </h3>
                <p className="text-avis mt-2">
                  Tiết kiệm thời gian và rắc rối bằng cách thêm Tùy chọn Dịch vụ
                  Nhiên liệu của chúng tôi vào lần đặt chỗ trực tuyến tiếp theo
                  của bạn. Chỉ cần trả trước cho một bình xăng đầy và mang xe về
                  không cần đầy. Không cần phải tìm kiếm trạm xăng trước khi trả
                  xe. Giá của chúng tôi cho dịch vụ này tương đương với giá bán
                  lẻ hiện tại tại địa phương.
                </p>
                <p className="text-avis mt-2">
                  Nếu bạn chọn tùy chọn này, hãy mang xe về càng trống càng tốt
                  vì sẽ không có tín dụng hoặc hoàn lại tiền cho nhiên liệu
                  không sử dụng trong bình.
                </p>
                <p className="text-avis mt-2">
                  Nếu Tùy chọn Dịch vụ Nhiên liệu không phải là điều bạn đang
                  tìm kiếm, hãy chọn một trong những tùy chọn nạp nhiên liệu phổ
                  biến khác khi thuê:
                </p>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">Tự phục vụ</h3>
                <p className="text-avis mt-2">
                  Không chắc bạn sẽ lái xe bao xa, nhưng dự định sẽ dừng lại để
                  đổ xăng? Đừng chấp nhận tùy chọn dịch vụ nhiên liệu. Chỉ cần
                  mua nhiên liệu của riêng bạn ngay trước khi bạn trả xe về Avis
                  với bình xăng đầy. Cung cấp biên lai mua nhiên liệu của bạn và
                  bạn sẽ tránh được bất kỳ phí dịch vụ nạp nhiên liệu nào. Dễ
                  đến thế nào?
                </p>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">
                  EZFuel® và Phí Dịch vụ Nhiên liệu
                </h3>
                <p className="text-avis mt-2">
                  Lái xe dưới 75 dặm? Với dịch vụ nạp nhiên liệu mới nhất của
                  chúng tôi, khách hàng lái xe dưới 75 dặm sẽ trả một khoản phí
                  cố định thấp cho nhiên liệu và sau đó trả lại xe mà không cần
                  dừng lại tại trạm xăng. Chọn tùy chọn này và chúng tôi sẽ tự
                  động tính phí cố định là{" "}
                  <b>$15,99, ($17,99 nếu bạn thuê xe ở California). </b>
                </p>
                <p className="text-avis mt-2">
                  Nếu bạn <b>**ĐỔ ĐẦY XE***</b> VÀ cung cấp biên lai mua nhiên
                  liệu của bạn, phí <b>EZFuel</b> sẽ bị xóa khi bạn trả xe.
                </p>
                <p className="text-avis mt-2">
                  Tùy chọn EZFuel không có sẵn tại tất cả các địa điểm. Vui lòng
                  liên hệ với địa điểm thuê của bạn để xác nhận tính sẵn có của
                  dịch vụ này.
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
