import React from "react";

export default class ET extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/onewaycarrental1.webp)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "200px",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb text-avis py-3">
                    <li className="breadcrumb-item">Xe & Dịch Vụ</li>
                    <li className="breadcrumb-item">Sản Phẩm & Dịch Vụ</li>
                    <li className="breadcrumb-item">Mỹ & Canada</li>
                    <li
                      className="breadcrumb-item link-avis"
                      aria-current="page"
                    >
                      Avis e-Toll
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="banner-one" style={myStyle}>
          <div className="banner-one__inner py-4">
            <div className="container">
              <div className="banner-one__content text-center">
                <div className="title">
                  <h3 className="text-white text-up">
                    DỊCH VỤ E-TOLL & CỔNG THU PHÍ KHÔNG TIỀN MẶT
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-2">
            <div className="row py-3">
              <div className="col-12">
                <p className="text-avis mt-2">
                  Avis rất vui mừng cung cấp dịch vụ xử lý cổng thu phí không
                  tiền mặt như một sự tiện lợi cho bạn. Chọn tiểu bang nhận xe
                  của bạn để biết thông tin chi tiết cụ thể.
                </p>
                <p className="text-avis mt-2">
                  Avis e-Toll được cung cấp tại Đông Bắc (từ Maine đến Bắc
                  Carolina), California, khu vực Chicago, Colorado, Florida,
                  Oklahoma, Texas, Washington và Puerto Rico.
                </p>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">
                  Cổng Thu Phí Không Tiền Mặt - Canada
                </h3>
                <p className="text-avis mt-2">
                  Xin lưu ý về những con đường có thể là "cổng thu phí không
                  tiền mặt" và người lái xe trên những con đường này không thể
                  trả tiền mặt khi đã đi vào những con đường này. Bạn tự động
                  tham gia chương trình dịch vụ e-Toll của chúng tôi và sẽ bị
                  tính phí $17.00 cộng với GST/HST mỗi lần thuê, cộng với các
                  khoản phí theo kilômét và cước phí video tại mức tối đa được
                  công bố bởi cơ quan thu phí. Các khoản phí có thể mất từ 4-8
                  tuần sau khi thuê để được tính vào tài khoản thanh toán hoặc
                  thẻ tín dụng/thẻ ghi nợ của bạn.
                </p>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="text-up text-black fw-normal">
                  Đông Bắc (Maine đến Bắc Carolina và Khu vực Chicago):
                </h3>
                <p className="text-avis mt-2">
                  California, Colorado, Delaware, Quận Columbia, Florida,
                  Georgia, Illinois, Indiana, Kansas, Maine, Maryland,
                  Massachusetts, New Hampshire, New Jersey, New York, Ohio,
                  Oklahoma, Pennsylvania, Rhode Island, Texas, Virginia,
                  Washington, Tây Virginia
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
