import React from "react";
import { Link } from "react-router-dom";

export default class AL extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      // backgroundImage: "url("+publicUrl+"assets/images/resources/tripideas1.webp)",
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      backgroundColor: "rgb(212, 0, 42)",
      height: "200px",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#fff",
    };
    const myBorder = {
      borderBottom: "2px solid #eaeaea",
    };
    return (
      <>
        <section style={myStyle}>
          <div className="container pt-4">
            <div className="row">
              <div className="col-sm-12 text-white">
                <h3 className="text-white text-up">
                  Thuê Xe Tại Sân Bay Qua Avis
                </h3>
                <p className="text-avis my-2">
                  Từ các sedan nhỏ cho các chuyến công tác cá nhân đến xe
                  minivan và SUV cho kỳ nghỉ gia đình, Avis cung cấp nhiều lựa
                  chọn thuê xe tại sân bay cho sự tiện lợi của bạn. Đi bộ nhanh
                  khỏi máy bay và nhanh chóng vào xe thuê. Có cách nào tốt hơn
                  để làm cho việc đi lại với trẻ nhỏ hoặc hành lý trở nên đơn
                  giản và dễ dàng? Thêm vào đó, với xe thuê tại sân bay có sẵn,
                  bạn có thể tham quan trong khoảng thời gian giữa các cuộc họp,
                  dừng chân trên đường đến các điểm đến và thay đổi kế hoạch một
                  cách nhanh chóng. Việc đi lại trở nên dễ dàng và thú vị hơn
                  với sự tự do của dịch vụ thuê xe tại sân bay từ Avis.
                </p>
                <b className="">
                  Duyệt qua các địa điểm sân bay tại Mỹ của chúng tôi dưới đây:
                </b>
              </div>
            </div>
          </div>
        </section>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item myLink" aria-current="page">
                      Quay lại Địa điểm
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-4">
            <div className="row" style={myBorder}>
              <div className="col-sm-12">
                <h3 className="text-black text-up">Thuê Xe Sân Bay Giá Rẻ</h3>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-4">
            <div className="row mt-3">
              <div className="col-sm-12">
                <h5 className="text-avis text-up">Mỹ</h5>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myBorder}>
                <h3 className="text-black text-up">Alaska</h3>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  Albania
                </a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  Albania
                </a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  Albania
                </a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  Albania
                </a>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myBorder}>
                <h3 className="text-black text-up">Alabama</h3>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  Albania
                </a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  Albania
                </a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  Albania
                </a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#" className="link-avis">
                  Albania
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-one" style={{ backgroundColor: "#000" }}>
          <div className="container py-3">
            <div className="row">
              <div className="col-sm-12">
                <h4 className="text-white text-up">
                  Làm thế nào để thuê xe tại sân bay?
                </h4>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myPara}>
                <p>
                  Việc thuê xe có thể đơn giản và nhanh chóng. Bắt đầu bằng cách
                  đặt chỗ trên ứng dụng Avis, avis.com hoặc gọi điện. Cần thuê
                  xe gấp tại sân bay? Không vấn đề gì, kiểm tra với quầy Avis để
                  xem có sẵn xe thuê hay không. Với một vài gợi ý và mẹo từ
                  Avis, bạn có thể ra khỏi sân bay và lên đường trong thời gian
                  ngắn.
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myPara}>
                <ol>
                  <li className="mb-3">
                    <b>+ Quầy Avis:</b> Mỗi bố trí sân bay khác nhau. Để tìm
                    quầy Avis nơi bạn đang hạ cánh, hãy tìm các biển chỉ dẫn bạn
                    đến khu vực thuê xe. Thông thường, các quầy thuê xe nằm gần
                    khu vực nhận hành lý. Tuy nhiên, một số sân bay có xe đưa
                    đón đến các địa điểm thuê xe ngoài sân bay.
                  </li>
                  <li className="mb-3">
                    <b>+ Đặt chỗ:</b> Việc đặt chỗ giúp cho trải nghiệm thuê xe
                    của bạn diễn ra suôn sẻ hơn. Thêm vào đó, với một đặt chỗ,
                    bạn sẽ có quyền truy cập vào mức giá tốt hơn.
                  </li>
                  <li className="mb-3">
                    <b>+ Khách vãng lai:</b> Nếu máy bay của bạn hạ cánh trong
                    giờ làm việc của quầy Avis tại chỗ, bạn có thể đến và hỏi
                    nhân viên dịch vụ khách hàng xem có xe thuê nào có sẵn
                    không. Thông thường, tất cả những gì bạn cần là một bằng lái
                    xe hợp lệ và một phương thức thanh toán. Nếu có xe khả dụng
                    trong thời gian bạn du lịch, bạn sẽ ký giấy tờ và lên đường.
                  </li>
                  <li className="mb-3">
                    <b>+ Giá và Tình trạng sẵn có:</b> Để xem xe thuê và giá nào
                    có sẵn, hãy chọn tab “Đặt chỗ”, sau đó chọn “Đặt chỗ” ở đầu
                    trang. Tiếp theo, điền vào biểu mẫu với ngày và địa điểm và
                    nhấp vào “Chọn Xe của Tôi”.
                  </li>
                  <li className="mb-3">
                    <b>+ Phiếu giảm giá và Giảm giá:</b> Trước khi đặt xe thuê
                    tại sân bay trên trang web hoặc ứng dụng Avis, hãy duyệt qua
                    trang “Ưu đãi tại Mỹ” trong tab “Ưu đãi”. Khi bạn đã tìm
                    thấy một ưu đãi áp dụng, hãy chọn nó và bắt đầu điền vào
                    biểu mẫu trên trang đó.
                  </li>
                  <li className="mb-3">
                    <b>+ Nâng cấp:</b> Trước khi đặt xe thuê tại sân bay trên
                    trang web hoặc ứng dụng Avis, hãy duyệt qua trang “Ưu đãi
                    tại Mỹ” trong tab “Ưu đãi”. Khi bạn đã tìm thấy một ưu đãi
                    áp dụng, hãy chọn nó và bắt đầu điền vào biểu mẫu trên trang
                    đó.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
