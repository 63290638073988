import React from "react";

const FuelOptions = () => {
  return (
    <>
      <section className="border-top border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb text-avis py-3">
                  <li className="breadcrumb-item">Ô tô & Dịch vụ</li>
                  <li className="breadcrumb-item">Sản phẩm & Dịch vụ</li>
                  <li className="breadcrumb-item link-avis" aria-current="page">
                    Tùy chọn Nhiên liệu
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-2">
          <div className="row py-3">
            <div className="col-12">
              <h3 className="text-up text-black fw-normal">
                Tiết kiệm thời gian với Đổ Nhiên Liệu Trước
              </h3>
              <p className="text-avis mt-2">
                Khi bạn nhận xe thuê từ Avis, xe sẽ được đổ đầy bình xăng. Trả
                lại xe với bình xăng đầy sau chuyến đi của bạn và sẽ không có
                khoản phí nào phải thanh toán.
              </p>
              <p className="text-avis mt-2">
                Để tránh phiền toái khi phải đổ xăng, chúng tôi cũng cung cấp
                các tùy chọn nhiên liệu khác. Bạn có thể chọn Đổ Nhiên Liệu
                Trước và yêu cầu một bình xăng hoặc dầu đầy, hoặc chọn EZ Fuel
                khi nhận xe, phù hợp với các chuyến đi ngắn hơn.
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <p className="text-avis mt-2">
                Đọc tiếp để xem tùy chọn nhiên liệu nào của Avis phù hợp nhất
                với bạn.
              </p>
              <h3 className="text-up text-black fw-normal">
                Các tùy chọn nhiên liệu từ Avis
              </h3>
              <h3 className="text-up text-black fw-normal mt-2">
                Đổ Đầy Trả Lại
              </h3>
              <p className="text-avis mt-2">
                Không có phí - ngay cả khi bạn đã chọn tùy chọn Đổ Nhiên Liệu
                Trước hoặc EZ Fuel khi bạn nhận xe. Chỉ cần trả lại xe với bình
                xăng đầy.
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <h3 className="text-up text-black fw-normal">
                Đổ Nhiên Liệu Trước
              </h3>
              <p className="text-avis mt-2">
                Mua một bình xăng đầy trước – với giá trung bình tại các trạm
                xăng – để bạn không cần phải đổ xăng trước khi trả lại xe.
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <h3 className="text-up text-black fw-normal">EZ Fuel</h3>
              <p className="text-avis mt-2">
                Lái xe dưới 120km? Với một khoản phí cố định trả trước, Avis sẽ
                lo việc đổ xăng cho bạn.
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <h3 className="text-up text-black fw-normal">
                Trả Phí Khi Trả Lại Xe
              </h3>
              <p className="text-avis mt-2">
                Nếu bạn không có thời gian đổ xăng và không chọn Đổ Nhiên Liệu
                Trước, chúng tôi có thể làm điều đó cho bạn. Tuy nhiên, đây là
                tùy chọn đắt nhất.
              </p>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-12">
              <h3 className="text-up text-black fw-normal">
                Thông tin chi tiết
              </h3>
              <ul>
                <li className="text-avis mt-2">
                  • Khi bạn nhận xe từ trạm Avis, nhân viên sẽ giải thích các
                  tùy chọn cho bạn và sắp xếp tùy chọn phù hợp nhất với nhu cầu
                  của bạn.
                </li>
                <li className="text-avis mt-2">
                  • Với <b>Đổ Nhiên Liệu Trước</b>, sẽ không có hoàn tiền cho
                  nhiên liệu chưa sử dụng trừ khi bạn <b>Đổ Đầy Trả Lại</b>. Nếu
                  bạn trả xe với bình xăng không đầy, bạn sẽ không được hoàn
                  tiền.
                </li>
                <li className="text-avis mt-2">
                  • Với <b>Trả Phí Khi Trả Lại Xe</b>, giá bao gồm cả chi phí
                  nhiên liệu và phí dịch vụ đổ xăng, nhưng bạn chỉ trả tiền cho
                  số lít nhiên liệu bạn đã sử dụng.
                </li>
                <li className="text-avis mt-2">
                  • Với <b>EZ Fuel</b>, Avis sẽ đổ đầy nhiên liệu cho xe của bạn
                  chỉ với £12 cộng VAT nếu bạn đã lái dưới 120km. Nếu bạn vẫn tự
                  đổ xăng, mang theo hóa đơn và chúng tôi sẽ hoàn lại phí này.
                </li>
                <li className="text-avis mt-2">
                  • Chính sách giá khác nhau tùy theo quốc gia.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FuelOptions;
